import React from "react";
import Slider from "react-slick";

const SquareImageSlider = ({ images }) => {
  const settings = {
    adaptiveHeight: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Slider {...settings}>
      {images && Array.isArray(images) ? (
        images.map((img, index) => (
          <div key={index} className="about-img-col">
            <img src={img} alt="About-us" />
          </div>
        ))
      ) : (
        <div className="about-img-col">
          <img src={images} alt="About-us" />
        </div>
      )}
    </Slider>
  );
};

export default SquareImageSlider;
