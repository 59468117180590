import React from "react";
import CountDown from "../CountDown";
import { getDateTime } from "../../Utils/format";

const TimerEventCard = ({ event, readmore }) => {
  return (
    <div className="col-md-12">
      <div className="event-1" key={event?.EventId}>
        <div className="recent-event-img">
          <img src={event?.DisplayURL} alt="" loading="eager" />
        </div>
        <div className="recent-event-detail">
          <h3 role="button" className="mb-3" onClick={() => readmore()}>
            {event?.EventTitle}
          </h3>
          <div className="recent-event-date">
            <i className="far fa-calendar-alt green-text"></i>{" "}
            <span className="pl-2">
              {event?.ScheduleDate ? getDateTime(event?.ScheduleDate) : ""}
            </span>{" "}
          </div>
          <div className="recent-event-date">
            <i className="fas fa-map-marker-alt green-text"></i>{" "}
            <span className="pl-2">{event?.EventLocation}</span>{" "}
          </div>
          <CountDown date={new Date(event?.ScheduleDate) ?? 0} />
        </div>
      </div>
    </div>
  );
};

export default TimerEventCard;
