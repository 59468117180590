export const URL_PATH = {
  home: "/",
  history: "/history",
  about: "/about",
  social: "/social-responsibility",
  gallery: "/gallery",
  contact: "/contactus",
  news: "/news",
  newsbyid: "/news",
  events: "/events",
  eventbyid: "/event",
  donation: "/donation",
  jobs: "/jobs",
  forgotpassword: "/forgotpassword",
  login: "/login",
  membership: "/membership",
  payment: "/payment",
  memberhome: "/home",
  memberprofile: "/profile",
  memberprofileedit: "/profile/edit",
  memberjobdashboard: "/dashboard/jobs",
  memberaddjob: "/dashboard/jobs/add",
  searchmember: "/profile/search",
  matrimonialprofile: "/matrimonial/profile",
  matrimonialdahboard: "/matrimonial/dahboard",
  createprofile: "/matrimonial/register",
  notfound: "/notfound",
};

export const API_PATHS = {
  header: "/api/Frontend/GetHeaderFooterDetails",
  home: "/api/Frontend/GetHomePageDetails",
  history: "/api/Frontend/GetHistoryPageDetails",
  about: "/api/Frontend/GetAboutPageDetails",
  social: "/api/Frontend/GetSocialResponsibilityDetails",
  gallery: "/api/Frontend/GetGalleryDetails",
  contact: "/api/Frontend/GetContactsDetails",
  news: "/api/Frontend/GetNewsDetails",
  newsbyid: "/api/Frontend/GetNewsDetailsById",
  events: "/api/Frontend/GetEventDetails",
  eventbyid: "/api/Frontend/GetEventDetailsById",
  donation: "/api/Frontend/GetDonationDetails",
  getNationalities: "/api/Master/GetNationalities",
  getGenders: "/api/Master/GetGenders",
  getDesignations: "/api/Master/GetDesignations",
  getCountries: "/api/Locations/GetCountries",
  getStates: "/api/Locations/GetStates",
  getDisticts: "/api/Locations/GetCities",
  createMember: "/api/MemberFrontend/CreateMember",
  loginMember: "/api/MemberFrontend/LoginMember",
  memberhome: "/api/MemberFrontend/GetMember",
  createPayment: "/api/Payment/CreatePaymentDetails",
  viewMember: "/api/MemberFrontend/ViewMember",
  updateMember: "/api/MemberFrontend/UpdateMember",
  memberEducation: "/api/MemberFrontend/GetMemberEducation",
  getDegrees: "/api/Master/GetDegrees",
  getGrades: "/api/Master/GetDegreeGrades",
};
