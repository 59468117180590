import React from "react";
import { Link } from "react-router-dom";
import notFound from "../Assets/img/404.png";

const NotFound = () => {
  return (
    <div class="error-case">
      <div class="container">
        <div class="img-404">
          <img src={notFound} alt="" />
        </div>
        <h2 class="main-title mt-3 mb-3 text-center">
          Oops! this page not found
        </h2>
        <div class="text-center">
          <Link to="/" class="btn green-btn">
            Back To Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
