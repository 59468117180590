import React from "react";

const MemberJobDashboard = () => {
  return (
    <div class="update-profile-body">
      <h2 class="main-title mt-3 mb-3">View Jobs</h2>
      <section class="search-member-body">
        <form action="" class="common-form">
          <div class="row">
            <div class="col-md-8">
              <div class="input-group searchbar-row">
                <input
                  type="text"
                  class="form-control"
                  name="searchTxt"
                  placeholder="Search"
                />
                <div class="input-group-append">
                  <button class="btn" type="submit">
                    <i class="fas fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <select class="custom-select">
                  <option selected="">Search by A-Z</option>
                  <option value="Search by A-Z">Search by A-Z</option>
                  <option value="Search by A-Z">Search by A-Z</option>
                </select>
              </div>
            </div>
          </div>
        </form>
        <div class="table-responsive custome-data-table">
          <table class="table">
            <thead>
              <tr>
                <th>Designation</th>
                <th>Company Name</th>
                <th>Job Location</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Software Engineer</td>
                <td>Tech Solutions Inc. </td>
                <td>San Francisco, CA</td>
              </tr>
              <tr>
                <td>Marketing Manager</td>
                <td>Creative Marketing</td>
                <td>New York, NY</td>
              </tr>
              <tr>
                <td>Data Analyst</td>
                <td>Data Insights Ltd.</td>
                <td>Austin, TX</td>
              </tr>
              <tr>
                <td>Product Manager </td>
                <td>Innovative Products </td>
                <td>Seattle, WA</td>
              </tr>
              <tr>
                <td>Sales Executive </td>
                <td>SalesPro Enterprises</td>
                <td>Chicago, IL</td>
              </tr>
              <tr>
                <td>UX Designer </td>
                <td>Design Studio Co.</td>
                <td>Boston, MA</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default MemberJobDashboard;
