import React from "react";

const MemberSearch = () => {
  return (
    <div className="update-profile-body">
      <h2 className="main-title mt-3 mb-3">Search Member</h2>
      <section className="search-member-body">
        <form action="" className="common-form">
          <div className="row">
            <div className="col-md-8">
              <div className="input-group searchbar-row">
                <input
                  type="text"
                  className="form-control"
                  name="searchTxt"
                  placeholder="Search"
                />
                <div className="input-group-append">
                  <button className="btn" type="submit">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <select className="custom-select">
                  <option selected="">Search by A-Z</option>
                  <option value="Search by A-Z">Search by A-Z</option>
                  <option value="Search by A-Z">Search by A-Z</option>
                </select>
              </div>
            </div>
          </div>
        </form>
        <div className="table-responsive custome-data-table">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>DOB</th>
                <th>Gender</th>
                <th>Address</th>
                <th>Phone No</th>
                <th>Email ID</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Kshiti Ghelani</td>
                <td>12-6-1992</td>
                <td>Female</td>
                <td>Ahmedabad, India</td>
                <td>123456789</td>
                <td>xyz@gmail.com</td>
              </tr>
              <tr>
                <td>Kshiti Ghelani</td>
                <td>12-6-1992</td>
                <td>Female</td>
                <td>Ahmedabad, India</td>
                <td>123456789</td>
                <td>xyz@gmail.com</td>
              </tr>
              <tr>
                <td>Kshiti Ghelani</td>
                <td>12-6-1992</td>
                <td>Female</td>
                <td>Ahmedabad, India</td>
                <td>123456789</td>
                <td>xyz@gmail.com</td>
              </tr>
              <tr>
                <td>Kshiti Ghelani</td>
                <td>12-6-1992</td>
                <td>Female</td>
                <td>Ahmedabad, India</td>
                <td>123456789</td>
                <td>xyz@gmail.com</td>
              </tr>
              <tr>
                <td>Kshiti Ghelani</td>
                <td>12-6-1992</td>
                <td>Female</td>
                <td>Ahmedabad, India</td>
                <td>123456789</td>
                <td>xyz@gmail.com</td>
              </tr>
              <tr>
                <td>Kshiti Ghelani</td>
                <td>12-6-1992</td>
                <td>Female</td>
                <td>Ahmedabad, India</td>
                <td>123456789</td>
                <td>xyz@gmail.com</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default MemberSearch;
