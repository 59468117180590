import React from "react";
import { Spinner } from "react-bootstrap";

const ConditionalLoader = ({ isLoading, children }) => {
  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "500px" }}
      >
        <Spinner />
      </div>
    );
  }
  return <>{children}</>;
};

export default ConditionalLoader;
