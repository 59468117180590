import React from "react";

const MatrimonialProfile = () => {
  return (
    <div className="view-member-profile">
      <div className="row">
        <div className="col-md-12 mb-4">
          <div className="row">
            <div className="col-md-3">
              <div className="profile-img">
                <img src="img/testimonal-1.png" alt="" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="profile-detail">
                <div className="mb-4">
                  <h2 className="sub-title-2">Kshiti Ghelani</h2>
                  <h3 className="sub-title-3">Software Engineer</h3>
                </div>
                <div>
                  <a
                    href="member-matrimonial-profile.html"
                    className="btn green-btn"
                  >
                    Edit Profile
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#tab-1">
                  Personal Detail
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#tab-2">
                  Address Detail
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#tab-3">
                  Partner Detail
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane container active" id="tab-1">
                <div className="view-member-profile-detail">
                  <ul className="view-profile-list">
                    <li>
                      <strong>First Name: </strong> <span>Kshiti </span>
                    </li>
                    <li>
                      <strong>Middle Name: </strong> <span>Kian</span>
                    </li>
                    <li>
                      <strong>Last Name: </strong> <span>Ghelani</span>
                    </li>
                    <li>
                      <strong>Gender: </strong> <span>Female</span>
                    </li>
                    <li>
                      <strong>Email Address: </strong>{" "}
                      <span>xyz@gmail.com</span>
                    </li>
                    <li>
                      <strong>Birth day: </strong> <span>12-7-1992 </span>
                    </li>
                    <li>
                      <strong>Mobile No: </strong> <span>123456789</span>
                    </li>
                    <li>
                      <strong>Religion: </strong> <span>Hindu</span>
                    </li>
                    <li>
                      <strong>Sub-Cast: </strong> <span>Patel</span>
                    </li>
                    <li>
                      <strong>Family Type: </strong> <span>Nuclear</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tab-pane container fade" id="tab-2">
                <div className="view-member-profile-detail">
                  <ul className="view-profile-list">
                    <li>
                      <strong>Address-1: </strong>{" "}
                      <span>C-404, Time square building</span>
                    </li>
                    <li>
                      <strong>Address-2: </strong>{" "}
                      <span>Near Business Hub.</span>
                    </li>
                    <li>
                      <strong>City: </strong> <span>Ahmedabad </span>
                    </li>
                    <li>
                      <strong>State: </strong> <span> Gujarat</span>
                    </li>
                    <li>
                      <strong>Pincode: </strong> <span> 383320</span>
                    </li>
                    <li>
                      <strong>Phone No: </strong> <span>123456789</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tab-pane container fade" id="tab-3">
                <div className="view-member-profile-detail">
                  <ul className="view-profile-list">
                    <li>
                      <strong>Min-Age: </strong> <span>26</span>
                    </li>
                    <li>
                      <strong>Max-Age: </strong> <span>28</span>
                    </li>
                    <li>
                      <strong>Min-Height: </strong> <span>5'6"</span>
                    </li>
                    <li>
                      <strong>Max-Height: </strong> <span> 6'0"</span>
                    </li>
                    <li>
                      <strong>Location: </strong> <span> Gujarat</span>
                    </li>
                    <li>
                      <strong>Education: </strong> <span> MTech</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="gallery-module">
            <div className="gallery-module-col">
              <a href="img/testimonal-1.png">
                <img src="img/testimonal-1.png" alt="" />
              </a>
            </div>
            <div className="gallery-module-col">
              <a href="img/testimonal-1.png">
                <img src="img/testimonal-1.png" alt="" />
              </a>
            </div>
            <div className="gallery-module-col">
              <a href="img/testimonal-1.png">
                <img src="img/testimonal-1.png" alt="" />
              </a>
            </div>
            <div className="gallery-module-col">
              <a href="img/testimonal-1.png">
                <img src="img/testimonal-1.png" alt="" />
              </a>
            </div>
            <div className="gallery-module-col">
              <a href="img/testimonal-1.png">
                <img src="img/testimonal-1.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatrimonialProfile;
