import React from "react";

const CreateProfile = () => {
  return (
    <div className="update-profile-body">
      <h2 className="main-title mt-3 mb-3">Create Matrimonial Profile</h2>
      <section className="update-profile-container">
        <div className="row d-flex justify-content-center">
          <div className="col-md-12">
            <div className="wizard memnership-form">
              <div className="wizard-inner">
                <div className="connecting-line"></div>
                <ul className="nav nav-tabs presentation-list" role="tablist">
                  <li role="presentation" className="active">
                    <a
                      href="#step1"
                      data-toggle="tab"
                      aria-controls="step1"
                      role="tab"
                      aria-expanded="true"
                    >
                      Personal Detail
                    </a>
                  </li>
                  <li role="presentation" className="disabled">
                    <a
                      href="#step2"
                      data-toggle="tab"
                      aria-controls="step2"
                      role="tab"
                      aria-expanded="false"
                    >
                      Address Detail
                    </a>
                  </li>
                  <li role="presentation" className="disabled">
                    <a
                      href="#step3"
                      data-toggle="tab"
                      aria-controls="step3"
                      role="tab"
                    >
                      Partner Detail
                    </a>
                  </li>
                  <li role="presentation" className="disabled">
                    <a
                      href="#step4"
                      data-toggle="tab"
                      aria-controls="step4"
                      role="tab"
                    >
                      Login Detail
                    </a>
                  </li>
                </ul>
              </div>

              <form role="form" action="index.html" className="common-form">
                <div className="tab-content" id="main_form">
                  <div className="tab-pane active" role="tabpanel" id="step1">
                    <h4 className="sub-title mb-4">Personal Detail</h4>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="First Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Middle Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <select className="custom-select">
                            <option selected>Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Email ID"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            onfocus="(this.type='date')"
                            placeholder="Birth day"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Mobile No"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Religion"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Sub-Cast"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group aimc-radio">
                          <label>Family Type:</label>
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="customRadio-1"
                              name="example1"
                              value="Join"
                            />
                            <label
                              className="custom-control-label"
                              for="customRadio-1"
                            >
                              Join
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="customRadio-2"
                              name="example1"
                              value="nuclear"
                            />
                            <label
                              className="custom-control-label"
                              for="customRadio-2"
                            >
                              Nuclear
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul className="list-inline text-right">
                      <li>
                        <button
                          type="button"
                          className="btn green-btn next-step"
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-pane" role="tabpanel" id="step2">
                    <h4 className="sub-title mb-4">Address Detail</h4>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Address - 1"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Address - 1"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <select className="custom-select">
                            <option selected>City</option>
                            <option value="Ahmedabad">Ahmedabad</option>
                            <option value="Baroda">Baroda</option>
                            <option value="Surat">Surat</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <select className="custom-select">
                            <option selected>State</option>
                            <option value="Gujarat">Gujarat</option>
                            <option value="Rajstan">Rajstan</option>
                            <option value="Delhi">Delhi</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Pincode"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Phone No"
                          />
                        </div>
                      </div>
                    </div>

                    <ul className="list-inline text-right d-flex justify-content-end">
                      <li>
                        <button
                          type="button"
                          className="btn green-btn prev-step mr-3"
                        >
                          Back
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          className="btn green-btn next-step"
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-pane" role="tabpanel" id="step3">
                    <h4 className="sub-title mb-4">Partner Detail</h4>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="d-flex">
                            <input
                              className="form-control mr-3"
                              type="text"
                              placeholder="Min-Age"
                            />
                            <input
                              className="form-control ml-3"
                              type="text"
                              placeholder="Max-Age"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="d-flex">
                            <select className="custom-select mr-3">
                              <option selected>Min-Height</option>
                              <option value="5'0">5'0"</option>
                              <option value="5'1">5'1"</option>
                              <option value="5'2">5'2"</option>
                            </select>
                            <select className="custom-select ml-3">
                              <option selected>Max-Height</option>
                              <option value="6'1">6'1"</option>
                              <option value="6'2">6'2"</option>
                              <option value="6'3">6'3"</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <select className="custom-select">
                            <option selected>Location</option>
                            <option value="Ahmedabad">Ahmedabad</option>
                            <option value="Baroda">Baroda</option>
                            <option value="Surat">Surat</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <select className="custom-select">
                            <option selected>Education</option>
                            <option value="Phd">Phd</option>
                            <option value="B.tech">B.tech</option>
                            <option value="M.tech">M.tech</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group aimc-radio">
                          <label>Family Type:</label>
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="customRadio-1"
                              name="example1"
                              value="Join"
                            />
                            <label
                              className="custom-control-label"
                              for="customRadio-1"
                            >
                              Join
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="customRadio-2"
                              name="example1"
                              value="nuclear"
                            />
                            <label
                              className="custom-control-label"
                              for="customRadio-2"
                            >
                              Nuclear
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul className="d-flex justify-content-end list-inline">
                      <li>
                        <button
                          type="button"
                          className="btn green-btn prev-step mr-3"
                        >
                          Back
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          className="btn green-btn next-step"
                        >
                          Continue
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-pane" role="tabpanel" id="step4">
                    <h4 className="sub-title mb-4">Login Detail</h4>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="User Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="password"
                            placeholder="Password"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="password"
                            placeholder="Confirm Password"
                          />
                        </div>
                      </div>
                    </div>

                    <ul className="list-inline d-flex justify-content-end">
                      <li>
                        <button
                          type="button"
                          className="btn green-btn prev-step mr-3"
                        >
                          Back
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          className="btn green-btn next-step"
                        >
                          Summit
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateProfile;
