import { API_PATHS, URL_PATH } from "./Constants/constants";

export const getApiUrl = (path) => {
  const newsRegex = /^\/news\/.*/i;
  const eventRegex = /^\/event\/.*/i;
  switch (path) {
    case URL_PATH.home:
      return API_PATHS.home;
    case URL_PATH.history:
      return API_PATHS.history;
    case URL_PATH.about:
      return API_PATHS.about;
    case URL_PATH.social:
      return API_PATHS.social;
    case URL_PATH.gallery:
      return API_PATHS.gallery;
    case URL_PATH.contact:
      return API_PATHS.contact;
    case URL_PATH.news:
      return API_PATHS.news;
    case URL_PATH.events:
      return API_PATHS.events;

    case URL_PATH.donation:
      return API_PATHS.donation;
    default:
      if (newsRegex.test(path)) return API_PATHS.newsbyid;
      if (eventRegex.test(path)) return API_PATHS.eventbyid;
      return "";
  }
};

export const getLimitHtmlContent = (htmlContent, len) => {
  if (!htmlContent || htmlContent.length <= len) return htmlContent;
  else {
    const stack = [];
    let res = "";
    let contLength = 0;
    for (let i = 0; i <= htmlContent.length; i++) {
      if (htmlContent[i] === "<") {
        if (htmlContent[i + 1] === "/") {
          while (htmlContent[i] !== ">") {
            res += htmlContent[i];
            i++;
          }
          stack.pop();
        } else {
          let tag = "";
          while (htmlContent[i] !== ">") {
            tag += htmlContent[i];
            res += htmlContent[i];
            i++;
          }
          tag += htmlContent[i];
          stack.push(tag);
        }
      }
      if (contLength >= len || i >= htmlContent.length) break;
      res += htmlContent[i];
      contLength++;
    }
    if (stack.length) {
      let i = stack.length - 1;
      while (i >= 0) {
        let tag = stack.pop();
        tag = tag.slice(0, 1) + "/" + tag.slice(1);
        if (i === 0) {
          res += "...";
        }
        res += tag;
        i--;
      }
    } else {
      if (res.length < htmlContent.length) res += "...";
    }
    return res;
  }
};

export const getDateTime = (date, format) => {
  if (!date) {
    return null;
  }
  const dateObject = new Date(date);
  if (dateObject === "Invalid Date") {
    return null;
  }
  if (format && format === "MMMM") {
    return dateObject.toLocaleDateString("en-US", {
      year: "numeric",
    });
  }
  return dateObject.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};
