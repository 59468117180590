import React, { useState } from "react";

const UserCard = ({ imgUrl, name, summary, duration }) => {
  return (
    <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
      <div className="card text-center border-none team-item-1">
        <div className="lab-inner">
          <div className="lab-thumb">
            <img src={imgUrl} className="card-img-top" alt="product" />
          </div>
          <div className="lab-content">
            <a href="#">
              <h6 className="card-title mb-0">{name}</h6>
            </a>
            <div className=" mb-3">
              <p className="card-text m-0">
                <strong>{summary}</strong>
              </p>
              <p className="card-text">{duration}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
