import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getApiResponse } from "../Utils/AxiosRequest";
import { getApiUrl } from "../Utils/format";
import Banner from "../Components/Banner";
import { Spinner } from "react-bootstrap";
import ButtonSlider from "../Components/Carousel/ButtonSlider";
import UEvenetCard from "../Components/Cards/UEvenetCard";
import { useNavigateToEvent } from "../Hooks/Navigation";

const EventDetails = () => {
  const [apiData, setapiData] = useState({});
  const [isLoading, setisLoading] = useState(true);
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const navigateToEvent = useNavigateToEvent();

  const pageBanner = apiData?.PageBanner;
  const event = apiData?.Events;
  const latestEvent = apiData?.LatestEvent;
  const moment = apiData?.Display_MomentImages;

  const breadCrumb = [
    {
      url: "/",
      title: "Home",
    },
    {
      url: "/events",
      title: "Events",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setisLoading(true);
        const apiPath = getApiUrl(pathname) + `/${id}`;
        const response = await getApiResponse(apiPath);
        if (response.status == 200) {
          setapiData(response.data);
        }
        setisLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id]);

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "500px" }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {pageBanner && pageBanner.length ? (
        <Banner
          breadCrumb={breadCrumb}
          heading={pageBanner[0].PageBannerTitle}
          imgUrl={pageBanner[0].DisplayURL}
          alt={"Event Detail"}
        />
      ) : (
        <></>
      )}
      <section className="event-detail">
        <div className="container">
          <div>
            <a
              href="event.html"
              title="Event"
              className="main-link"
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              <i className="fas fa-arrow-left"></i> Back to Events
            </a>
          </div>
          <h2 className="main-title">{event?.EventTitle}</h2>
          <div className="event-place-date">
            <div className="event-date">
              <span>{event?.ScheduleDate}</span>
            </div>
            <div className="event-place">
              Location : <span>{event?.EventLocation}</span>
            </div>
          </div>
          <div className="event-img-1">
            <img src={event?.DisplayURL} alt="" />
          </div>
          <div
            className="html_render"
            dangerouslySetInnerHTML={{
              __html: event?.EventContent,
            }}
          ></div>
        </div>
      </section>
      {latestEvent && latestEvent.length ? (
        <section className="latest-event pb-4 mb-4">
          <div className="container">
            <h2 className="main-title mb-3">Related Events</h2>
            <div className="latest-event-row">
              <ButtonSlider>
                <>
                  {latestEvent.map((event, index) => (
                    <div className="col-lg-12 mt-4">
                      <div className="event-2">
                        <div className="event-img">
                          <img src={event?.DisplayURL} alt="" />
                        </div>
                        <div className="event-body">
                          <h3>
                            <a
                              href={"#"}
                              className="sub-title-2 d-block text-truncate pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                navigateToEvent(event?.EventId);
                              }}
                            >
                              {event?.EventTitle}
                            </a>
                          </h3>
                          {event?.ScheduleDate && (
                            <div className="recent-event-date">
                              <i className="far fa-calendar-alt green-text"></i>{" "}
                              <span className="pl-2">
                                {event?.ScheduleDate}
                              </span>{" "}
                            </div>
                          )}
                          {event?.location && (
                            <div className="recent-event-date">
                              <i className="fas fa-map-marker-alt green-text"></i>{" "}
                              <span className="pl-2">{event.location}</span>{" "}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              </ButtonSlider>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  );
};

export default EventDetails;
