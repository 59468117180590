import { useNavigate } from "react-router-dom";
import { URL_PATH } from "../Utils/Constants/constants";

export const useNavigateToNews = () => {
  const navigate = useNavigate();
  return (id) => {
    navigate(URL_PATH.newsbyid + `/${id}`);
  };
};

export const useNavigateToEvent = () => {
  const navigate = useNavigate();
  return (id) => {
    navigate(URL_PATH.eventbyid + `/${id}`);
  };
};
