import React from "react";
import phoneIcon from "../../Assets/img/phone.svg";
import addressIcon from "../../Assets/img/address-icon.svg";
import mailIcon from "../../Assets/img/mail.svg";

const OfficeAddressCard = ({ office }) => {
  return (
    <div className="our-office-container">
      <div className="our-office-col-1">
        <h3>{office?.OfficeName}</h3>
        <ul className="our-office-address">
          <li>
            <div className="d-flex align-items-center">
              <div className="icon-width-1">
                <span>
                  <img src={mailIcon} alt="" />
                </span>
              </div>
              <div className="icon-width-2">
                <a href={`mailto:${office?.EmailAddress}`}>
                  {office?.EmailAddress}
                </a>
              </div>
            </div>
          </li>
          <li>
            <div className="d-flex align-items-center">
              <div className="icon-width-1">
                <span>
                  <img src={phoneIcon} alt="" />
                </span>
              </div>
              <div className="icon-width-2">
                <a href={`tel:${office?.ContactInfo}`}>
                  {office?.ContactInfo}{" "}
                </a>
              </div>
            </div>
          </li>
          <li>
            <div className="d-flex align-items-center">
              <div className="icon-width-1">
                <span>
                  <img src={addressIcon} alt="" />
                </span>
              </div>
              <div className="icon-width-2">
                <a href="#">{office?.FullAddress}</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
      {office?.LocationUrl && (
        <div className="our-office-col-2">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448193.951046296!2d76.76356261822156!3d28.644287349748502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x37205b715389640!2sDelhi!5e0!3m2!1sen!2sin!4v1725271200489!5m2!1sen!2sin"
            width="600"
            height="450"
            style={{ border: "0" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default OfficeAddressCard;
