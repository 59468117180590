import React, { useContext } from "react";
import { Spinner } from "react-bootstrap";
import { MemberDetails } from "../../Contexts/MemberDetailContext";

const MemberHome = () => {
  const { isLoading, memberDetails, error } = useContext(MemberDetails);

  if (error) {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ minHeight: "500px" }}
      >
        <h1>{error}</h1>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "500px" }}
      >
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <div className="active-membership-icon">
        <div className="row">
          <div className="col-sm-3 mb-3">
            <div className="color-card color-bg-1">
              <h2 className="main-title text-white">16</h2>
              <h3 className="sub-title text-white">Total Viewed</h3>
            </div>
          </div>
          <div className="col-sm-3 mb-3">
            <div className="color-card color-bg-2">
              <h2 className="main-title text-white">14</h2>
              <h3 className="sub-title text-white">Total Liked</h3>
            </div>
          </div>
          <div className="col-sm-3 mb-3">
            <div className="color-card color-bg-3">
              <h2 className="main-title text-white">5</h2>
              <h3 className="sub-title text-white">Profiles You Liked</h3>
            </div>
          </div>
          <div className="col-sm-3 mb-3">
            <div className="color-card color-bg-4">
              <h2 className="main-title text-white">16</h2>
              <h3 className="sub-title text-white">Messages Received</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="active-membership-dashboard">
        <div className="row">
          <div className="col-md-7 mb-3">
            <div className="membership-dashboard-detail">
              <h2 className="sub-title-2 mb-3">Profile Information</h2>
              <div className="profile-img">
                <img src={memberDetails.DisplayURL} alt="" />
              </div>
              <div className="view-member-profile-detail">
                <ul className="view-profile-list">
                  <li>
                    <strong>Name: </strong>{" "}
                    <span>{memberDetails.FirstName ?? ""} </span>
                  </li>
                  <li>
                    <strong>Gender: </strong>{" "}
                    <span>{memberDetails.LastName ?? ""}</span>
                  </li>
                  <li>
                    <strong>Age: </strong> <span>30</span>
                  </li>
                  <li>
                    <strong>Religion/Caste: </strong> <span>Hindu/Brahmin</span>
                  </li>
                  <li>
                    <strong>Occupation: </strong>{" "}
                    <span>{memberDetails?.CurrentDesignation ?? ""}</span>
                  </li>
                  <li>
                    <strong>Location: </strong>{" "}
                    <span>
                      {memberDetails.District + ", " + memberDetails.State ??
                        ""}
                    </span>
                  </li>
                  <li>
                    <strong>Email: </strong>{" "}
                    <span>{memberDetails.Email ?? ""}</span>
                  </li>
                  <li>
                    <strong>Phone Number: </strong>{" "}
                    <span>{memberDetails.PhoneNumber ?? ""}</span>
                  </li>
                  <li>
                    <strong>Profile ID: </strong>{" "}
                    <span>{memberDetails?.MemberUniqueId ?? ""}</span>
                  </li>
                  {/* <li>
                    <strong>Membership Level: </strong> <span> Premium</span>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="membership-dashboard-detail mb-3">
              <h2 className="sub-title-2 mb-3">Membership Overview</h2>
              <div className="view-member-profile-detail">
                <ul className="view-profile-list">
                  <li>
                    <strong className="w-100">Current Plan: </strong>{" "}
                    <span className="w-100">Premium (Active) </span>
                  </li>
                  <li>
                    <strong className="w-100">Membership Start Date: </strong>{" "}
                    <span className="w-100">January 1, 2024</span>
                  </li>
                  <li>
                    <strong className="w-100">Membership Expiry Date: </strong>{" "}
                    <span className="w-100">December 31, 2024</span>
                  </li>
                </ul>
                <a href="#" className="btn green-btn">
                  Upgrade/Downgrade Membership
                </a>
              </div>
            </div>
            <div className="membership-dashboard-detail mb-3">
              <h2 className="sub-title-2 mb-3">Partner Preferences</h2>
              <div className="view-member-profile-detail">
                <ul className="view-profile-list">
                  <li>
                    <strong className="w-100">Preferred Age Range: </strong>{" "}
                    <span className="w-100">25 - 32 </span>
                  </li>
                  <li>
                    <strong className="w-100">
                      Preferred Religion/Caste:{" "}
                    </strong>{" "}
                    <span className="w-100">Hindu/Brahmin</span>
                  </li>
                  <li>
                    <strong className="w-100">Preferred Education: </strong>{" "}
                    <span className="w-100"> Master's Degree</span>
                  </li>
                  <li>
                    <strong className="w-100">Preferred Location: </strong>{" "}
                    <span className="w-100"> USA</span>
                  </li>
                  <li>
                    <strong className="w-100">
                      Preferred Marital Status:{" "}
                    </strong>{" "}
                    <span className="w-100"> Never Married</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="col-md-12">
            <div className="membership-dashboard-detail">
              <h2 className="sub-title-2 mb-3">Payment History</h2>
              <div className="table-responsive custome-data-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Method</th>
                      <th>Invoice</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>January 1, 2024 </td>
                      <td>Rs.500 </td>
                      <td>Credit Card</td>
                      <td>
                        <a href="#">
                          <i className="fas fa-download"></i> Download Invoice
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>January 1, 2023 </td>
                      <td>Rs.500 </td>
                      <td>Credit Card</td>
                      <td>
                        <a href="#">
                          <i className="fas fa-download"></i> Download Invoice
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>January 1, 2022 </td>
                      <td>Rs.500 </td>
                      <td>Credit Card</td>
                      <td>
                        <a href="#">
                          <i className="fas fa-download"></i> Download Invoice
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default MemberHome;
