import React, { useContext, useState } from "react";
import HomeEvent from "../Components/Cards/EventCard";
import UEvenetCard from "../Components/Cards/UEvenetCard";

import d1 from "../Assets/img/d-1.png";
import icon1 from "../Assets/img/icon-1.png";
import icon2 from "../Assets/img/icon-2.png";
import icon3 from "../Assets/img/icon-3.png";
import icon4 from "../Assets/img/icon-4.png";
import ButtonSlider from "../Components/Carousel/ButtonSlider";
import MainCarousel from "../Components/Carousel/MainCarousel";
import SquareImageSlider from "../Components/Carousel/SquareImageSlider";
import NewsCard from "../Components/Cards/NewsCard";
import { ApiDataContext } from "../Contexts/ApiData";
import Modal from "../Components/Modal";
import TimerEventCard from "../Components/Cards/TimerEventCard";
import { getLimitHtmlContent } from "../Utils/format";
import { useNavigateToNews, useNavigateToEvent } from "../Hooks/Navigation";

const Home = () => {
  const [showHistoryModel, setshowHistoryModel] = useState(false);

  const contextApiData = useContext(ApiDataContext);

  const navigateToNews = useNavigateToNews();
  const navigateToEvent = useNavigateToEvent();

  const filterEvent = (events) => {
    return events
      .filter((i) => {
        const date = new Date(i.ScheduleDate);
        if (date) return date.getTime() > Date.now();
        return false;
      })
      .sort((a, b) => {
        const d1 = a.ScheduleDate;
        const d2 = b.ScheduleDate;
        if (d1 && d2) {
          const date1 = Date(d1);
          const date2 = Date(d2);
          if (date1 > date2) return 1;
          else if (date2 > date1) return -1;
        }
        return 0;
      });
  };

  const historyImagesPath = contextApiData?.CMSPage?.Display_HistoryImagePaths;
  const presidentPhotoPath = contextApiData?.CMSPage?.Display_PresidentPhoto;
  const presidentMessage = contextApiData?.CMSPage?.PresidentMessage;
  const presidentContent = contextApiData?.CMSPage?.PresidentContent;
  const homeHistoryContent = contextApiData?.CMSPage?.HomeHistoryContent;
  const impactTitle1 = contextApiData?.CMSPage?.ImpactTitle1;
  const impactTitle2 = contextApiData?.CMSPage?.ImpactTitle2;
  const impactTitle3 = contextApiData?.CMSPage?.ImpactTitle3;
  const impactTitle4 = contextApiData?.CMSPage?.ImpactTitle4;
  // const carousel = contextApiData?.Display_HomeSlider;
  const newsList = contextApiData?.NewsList;
  const donationList = contextApiData?.DonationList;
  const bannerList = contextApiData?.BannerList;

  const events = contextApiData?.EventList;
  const eventList = events ? filterEvent(events) : [];

  return (
    <>
      <MainCarousel
        carousel={bannerList}
        presidentContent={presidentContent}
        presidentImage={presidentPhotoPath}
        presidentMessage={presidentMessage}
      />
      <section className="about-us">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="sub-title mb-3">Our History</h2>
              <div
                className="html_render"
                dangerouslySetInnerHTML={{
                  __html: getLimitHtmlContent(homeHistoryContent, 400),
                }}
              ></div>

              <div className="mt-3">
                <button
                  className="btn green-btn"
                  onClick={() => setshowHistoryModel(true)}
                >
                  Read More <i className="fas fa-heart"></i>
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-img">
                <div className="about-img-row">
                  <SquareImageSlider images={historyImagesPath} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          showModal={showHistoryModel}
          closeModal={setshowHistoryModel}
          heading={"Our History"}
          summary={homeHistoryContent}
        />
      </section>
      <section className="key-point">
        <div className="container">
          <h2 className="main-title text-center mb-4">Our Impact</h2>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="key-point-body">
                <div className="key-point-icon mb-3">
                  <img src={icon1} alt="icon" />
                </div>
                <h2 className="sub-title-2">
                  <a href="#">{impactTitle1}</a>
                </h2>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="key-point-body">
                <div className="key-point-icon mb-3">
                  <img src={icon2} alt="icon" />
                </div>
                <h2 className="sub-title-2">
                  <a href="#">{impactTitle2}</a>
                </h2>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="key-point-body">
                <div className="key-point-icon mb-3">
                  <img src={icon3} alt="icon" />
                </div>
                <h2 className="sub-title-2">
                  <a href="#">{impactTitle3}</a>
                </h2>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="key-point-body">
                <div className="key-point-icon mb-3">
                  <img src={icon4} alt="icon" />
                </div>
                <h2 className="sub-title-2">
                  <a href="#">{impactTitle4}</a>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {newsList && newsList.length ? (
        <section className="monin-service">
          <div className="container">
            <div className="monin-service-title">
              <h2 className="sub-title text-center mb-3">Momin Latest News</h2>
              <h3 className="main-title text-center mb-5">
                Empowering Momin: Tailored News for the Muslim Community
              </h3>
            </div>
            <div className="latest-news-row">
              <ButtonSlider>
                <>
                  {newsList.map((news, index) => (
                    <NewsCard
                      key={news?.NewsId ?? index}
                      imgUrl={news?.DisplayURL}
                      date={news?.ScheduleDate}
                      heading={news?.NewsTitle}
                      summary={news?.NewsContent}
                      readmore={() => navigateToNews(news?.NewsId)}
                    />
                  ))}
                </>
              </ButtonSlider>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
      <section className="awards">
        <div className="container">
          <h2 className="main-title mb-3">Donation Programs</h2>
          <div className="awards-row">
            <ButtonSlider>
              <>
                {donationList &&
                  donationList.map((event, index) => (
                    <HomeEvent
                      id={event?.DonationId}
                      key={event?.DonationId ?? index}
                      imgUrl={event?.DisplayURL}
                      iconUrl={d1}
                      heading={event?.DonationName}
                      summary={event?.DonationContent}
                      date={event?.DonationDate}
                    />
                  ))}
              </>
            </ButtonSlider>
          </div>
        </div>
      </section>
      <section className="upcoming-event">
        <div className="container">
          <div className="monin-service-title">
            <h2 className="sub-title text-center mb-3">upcoming event</h2>
            <h3 className="main-title text-center mb-5">
              {eventList && eventList.length ? eventList[0]?.EventTitle : ""}
            </h3>
          </div>
          {eventList && eventList.length ? (
            <div className="row">
              <TimerEventCard
                event={eventList[0]}
                readmore={() => navigateToEvent(eventList[0]?.EventId)}
              />

              <div className="col-md-12">
                <div className="row">
                  {eventList &&
                    eventList.map(
                      (uEvenet, index) =>
                        index > 0 && (
                          <UEvenetCard
                            key={uEvenet?.EventId}
                            heading={uEvenet?.EventTitle}
                            date={uEvenet?.ScheduleDate?.slice(0, 10)}
                            imgUrl={
                              process.env.REACT_APP_API_URL +
                              "/" +
                              uEvenet?.EventImagePath
                            }
                            location={uEvenet?.EventLocation}
                            url={uEvenet?.url}
                            summary={uEvenet?.EventContent}
                            readmore={() => navigateToEvent(uEvenet?.EventId)}
                          />
                        )
                    )}
                </div>
              </div>
            </div>
          ) : (
            <h6 className="text-center">No Upcoming event</h6>
          )}
        </div>
      </section>
    </>
  );
};

export default Home;
