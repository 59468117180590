import "../Styles/App.css";
import DefaultLayout from "../Layout/DefaultLayout.jsx";
import AllRoutes from "../Routes/AllRoutes.jsx";
import { LoginContext } from "../Contexts/LoginContext.js";
import { useEffect, useState } from "react";
import { getSessionIdToken } from "../Utils/Auth/session.js";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    const { token, id } = getSessionIdToken();
    if (!token || !id) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <div className="App">
      <LoginContext.Provider
        value={{
          isLoggedIn,
          setIsLoggedIn,
        }}
      >
        <DefaultLayout>
          <AllRoutes />
        </DefaultLayout>
      </LoginContext.Provider>
    </div>
  );
}

export default App;
