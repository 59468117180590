import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { API_PATHS, URL_PATH } from "../Utils/Constants/constants";
import { LoginContext } from "../Contexts/LoginContext";
import { clearSession } from "../Utils/Auth/session";
import { getApiResponse } from "../Utils/AxiosRequest";
import { MemberDetails } from "../Contexts/MemberDetailContext";
import Modal from "../Components/Modal";

const MembershipLayout = () => {
  const [showNav, setshowNav] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState({
    heading: "",
    description: "",
  });
  const { pathname } = useLocation();
  const { setIsLoggedIn } = useContext(LoginContext);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [apiData, setApiData] = useState({});
  const navigate = useNavigate();

  const firstName = apiData?.MemberDetails?.FirstName ?? "";
  const lastName = apiData?.MemberDetails?.LastName ?? "";

  let page = "Home";
  switch (pathname) {
    case URL_PATH?.memberhome:
      page = "Home";
      break;
    case URL_PATH?.memberprofile:
      page = "View Profile";
      break;
    case URL_PATH?.memberprofileedit:
      page = "Update Profile";
      break;
  }

  const toggleNav = () => {
    setshowNav((prvs) => !prvs);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    clearSession();
    setIsLoggedIn(false);
    navigate(URL_PATH.login);
  };
  const onCloseModal = () => {
    clearSession();
    setIsLoggedIn(false);
    navigate(URL_PATH.login);
  };

  const fetchData = async () => {
    setError("");
    let apiPath = API_PATHS.viewMember;
    try {
      setIsLoading(true);
      const response = await getApiResponse(apiPath, true);

      if (response.status == 200) {
        setApiData(response?.data ?? {});
      } else {
        setMessage({
          heading: "Something went wrong",
          description: "Please login again",
        });
        setShowModal(true);
        setError("Something went wrong");
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setMessage({
        heading: "Something went wrong",
        description: "Please login again",
      });
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (!apiData.MemberId) {
      fetchData();
    }
  }, []);

  return (
    <section id="wrapper" className={showNav ? "active" : ""}>
      <aside className="side-menu">
        <div className="side-menu-inner">
          <div className="container">
            <div className="close-btn-1">
              <button
                id="btn-close-1 focus-ring"
                className="btn"
                onClick={toggleNav}
              >
                <i className="fas fa-times-circle"></i>
              </button>
            </div>
            <div className="user-id">
              <div className="user-img">
                <img src={apiData?.MemberDetails?.DisplayURL} alt="" />
              </div>
              <div className="user-title">
                <span>Hello,</span>
                <h2>
                  <span
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    {firstName}
                  </span>
                  <span> </span>
                  <span
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    {lastName}
                  </span>
                </h2>
              </div>
            </div>
            <div>
              <div className="accordion" id="side-menu">
                <div className="card">
                  <div className="card-header" id="side-menu-1">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#menu1"
                      aria-expanded="true"
                      aria-controls="menu1"
                    >
                      <i className="fas fa-user"></i> Account{" "}
                    </a>
                  </div>
                  <div
                    id="menu1"
                    className="collapse show"
                    aria-labelledby="side-menu-1"
                    data-parent="#side-menu"
                  >
                    <div className="card-body">
                      <ul>
                        <li
                          className={`${
                            pathname === URL_PATH.memberhome ? "active" : ""
                          }`}
                        >
                          <Link to={URL_PATH.memberhome}>Home</Link>
                        </li>
                        <li
                          className={`${
                            pathname === URL_PATH.memberprofile ? "active" : ""
                          }`}
                        >
                          <Link to={URL_PATH.memberprofile}>View Profile</Link>
                        </li>
                        <li
                          className={`${
                            pathname === URL_PATH.memberprofileedit
                              ? "active"
                              : ""
                          }`}
                        >
                          <Link to={URL_PATH.memberprofileedit}>
                            Update Profile
                          </Link>
                        </li>
                        <li
                          className={`${
                            pathname === URL_PATH.searchmember ? "active" : ""
                          }`}
                        >
                          <Link to={URL_PATH.searchmember}>Search Member</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="side-menu-2">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#menu2"
                      aria-expanded="true"
                      aria-controls="menu2"
                    >
                      <i className="fas fa-heart"></i> Matrimonial
                    </a>
                  </div>

                  <div
                    id="menu2"
                    className="collapse"
                    aria-labelledby="side-menu-2"
                    data-parent="#side-menu"
                  >
                    <div className="card-body">
                      <ul>
                        <li>
                          <Link to={URL_PATH.createprofile}>
                            Create Profile
                          </Link>
                        </li>
                        <li>
                          <Link to={URL_PATH.matrimonialdahboard}>
                            Matrimonial Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link to={URL_PATH.matrimonialprofile}>
                            Matrimonial Profile
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="side-menu-3">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#menu3"
                      aria-expanded="true"
                      aria-controls="menu3"
                    >
                      <i className="fas fa-briefcase"></i> Job & Vacancy
                    </a>
                  </div>

                  <div
                    id="menu3"
                    className="collapse"
                    aria-labelledby="side-menu-3"
                    data-parent="#side-menu"
                  >
                    <div className="card-body">
                      <ul>
                        <li>
                          <Link to={URL_PATH.memberjobdashboard}>
                            View Jobs
                          </Link>
                        </li>
                        <li>
                          <Link to={URL_PATH.memberaddjob}>Add Jobs</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="log-out">
                <a href="#" onClick={handleLogout}>
                  <i className="fas fa-sign-out-alt"></i> Log Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </aside>
      <div id="content">
        <div className="container">
          <div className="breadcrumb-body">
            <button className="btn bar-btn" onClick={toggleNav}>
              <i className="fas fa-bars"></i>
            </button>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Account</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {page}
                </li>
              </ol>
            </nav>
          </div>
          <MemberDetails.Provider
            value={{
              memberDetails: apiData?.MemberDetails ?? {},
              education: apiData?.MemberEducation ?? [],
              workExperience: apiData?.MemberWorkExperience ?? [],
              isLoading,
              error,
            }}
          >
            <Outlet />
          </MemberDetails.Provider>
        </div>
      </div>
      <Modal
        showModal={showModal}
        closeModal={onCloseModal}
        heading={message.heading}
        summary={message.description}
      />
    </section>
  );
};

export default MembershipLayout;
