import React from "react";
import { useForm } from "react-hook-form";
import ErrorsList from "../ErrorsList";

const EducationRow = ({
  isSubmitting,
  register,
  index,
  values,
  errors,
  isDirty,
  grades,
  degrees,
  item,
  lastItem,
  setValue,
  getValues,
  remove,
}) => {
  const validateDegreeId = (v) => {
    return v != 0 || "Please select Degree";
  };

  const validateGradeId = (v) => {
    return v != 0 || "Please select Grades";
  };

  const checkSpecialCharacters = (v, name) => {
    return (
      /^[a-zA-Z 0-9]+$/.test(v) || `Special characters not allowed in ${name}`
    );
  };
  const checkOnlyNumbers = (v, name) => {
    return /^[0-9]+$/.test(v) || `Only numbers are allowed ${name}`;
  };
  // pattern: {
  //   value: /^[a-zA-Z0-9]/,
  //   message: "Special characters not allowed",
  // },
  return (
    <>
      <div
        className={`row add-education-row mt-4 p-3 pb-0 ${
          lastItem ? "" : "border-bottom"
        }`}
      >
        <div className="col-md-4">
          <div className="form-group">
            <select
              className={`custom-select ${
                errors?.DegreeId && isDirty
                  ? "red-placeholder border border-danger"
                  : ""
              }`}
              {...register(`forms.${index}.DegreeId`, {
                required: "Please select degree",
                validate: validateDegreeId,
              })}
              disabled={isSubmitting}
            >
              <option key={0} value={0}>
                Degree
              </option>
              {degrees.length > 0 &&
                degrees.map((item) => (
                  <option key={item.DegreeId} value={item.DegreeId}>
                    {item.DegreeName.toUpperCase()}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${
                errors?.StartDate && isDirty
                  ? "red-placeholder border border-danger"
                  : ""
              }`}
              placeholder={
                errors?.StartDate && isDirty
                  ? errors?.StartDate.message
                  : "Start Date"
              }
              {...register(`forms.${index}.StartDate`, {
                max: {
                  value: new Date().toLocaleDateString(),
                  message: "Please select a valid Start Date",
                },
              })}
              onFocus={(e) => {
                e.target.type = "date";
              }}
              onBlur={(e) => {
                e.target.type = "text";
              }}
              disabled={isSubmitting}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${
                errors?.EndDate && isDirty
                  ? "red-placeholder border border-danger"
                  : ""
              }`}
              placeholder={
                errors?.EndDate && isDirty
                  ? errors?.EndDate.message
                  : "End Date"
              }
              {...register(`forms.${index}.EndDate`, {
                max: {
                  value: new Date().toLocaleDateString(),
                  message: "Please select a valid End Date",
                },
              })}
              onFocus={(e) => {
                e.target.type = "date";
              }}
              onBlur={(e) => {
                e.target.type = "text";
              }}
              disabled={isSubmitting}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${
                errors?.InstitutionName && isDirty
                  ? "red-placeholder border border-danger"
                  : ""
              }`}
              placeholder={
                errors?.InstitutionName && isDirty
                  ? errors?.InstitutionName.message
                  : "Institution"
              }
              {...register(`forms.${index}.InstitutionName`, {
                required: "Please enter your institution name",
                validate: (v) => checkSpecialCharacters(v, "Institution Name"),
              })}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <select
              className={`custom-select ${
                errors?.DegreeGradeId && isDirty
                  ? "red-placeholder border border-danger"
                  : ""
              }`}
              {...register(`forms.${index}.DegreeGradeId`, {
                required: "Please select grades",
                validate: validateGradeId,
              })}
              disabled={isSubmitting}
            >
              <option key={0} value={0}>
                Grade
              </option>
              {grades.length > 0 &&
                grades.map((item) => (
                  <option key={item.DegreeGradeId} value={item.DegreeGradeId}>
                    {item.GradeName.toUpperCase()}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${
                errors?.DegreeDescription && isDirty
                  ? "red-placeholder border border-danger"
                  : ""
              }`}
              placeholder={
                errors?.DegreeDescription && isDirty
                  ? errors?.DegreeDescription.message
                  : "Degree description"
              }
              {...register(`forms.${index}.DegreeDescription`, {
                validate: (v) =>
                  checkSpecialCharacters(v, "Degree description"),
              })}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${
                errors?.FieldOfStudy && isDirty
                  ? "red-placeholder border border-danger"
                  : ""
              }`}
              placeholder={
                errors?.FieldOfStudy && isDirty
                  ? errors?.FieldOfStudy.message
                  : "Field of Study"
              }
              {...register(`forms.${index}.FieldOfStudy`, {
                required: "Please enter your field of study",
                validate: (v) => checkSpecialCharacters(v, "Field of Study"),
              })}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${
                errors?.DegreePercentage && isDirty
                  ? "red-placeholder border border-danger"
                  : ""
              }`}
              placeholder={
                errors?.DegreePercentage && isDirty
                  ? errors?.DegreePercentage.message
                  : "Percentage"
              }
              {...register(`forms.${index}.DegreePercentage`, {
                required: "Please enter your percentage",
                min: {
                  value: 0,
                  message: "Percentage can not be below 0",
                },
                max: {
                  value: 100,
                  message: "Percentage can not be above 100",
                },
                validate: (v) => checkOnlyNumbers(v, "Percentage"),
              })}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${
                errors?.PassingYear && isDirty
                  ? "red-placeholder border border-danger"
                  : ""
              }`}
              placeholder={
                errors?.PassingYear && isDirty
                  ? errors?.PassingYear.message
                  : "Passing Year"
              }
              {...register(`forms.${index}.PassingYear`, {
                required: "Please enter your passing year",
                min: {
                  value: 1900,
                  message: "Please enter a valid year e.g., 2001",
                },
                max: {
                  value: new Date().getFullYear(),
                  message: "Please enter a valid year e.g., 2001",
                },
                validate: (v) => checkOnlyNumbers(v, "Passing Year"),
              })}
            />
          </div>
        </div>
        <div className="col-12 d-flex justify-content-end">
          <button
            className="btn btn-danger btn-sm"
            onClick={() => {
              remove(index);
            }}
          >
            Delete
          </button>
        </div>
      </div>
      <ErrorsList isDirty={isDirty} errors={errors} />
    </>
  );
};

export default EducationRow;
