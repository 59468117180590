import React from "react";

const MatrimonialDashboard = () => {
  return (
    <div className="container">
      <div className="breadcrumb-body">
        <button className="btn bar-btn">
          <i className="fas fa-bars"></i>
        </button>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">Account</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Matrimonial Dashboard
            </li>
          </ol>
        </nav>
      </div>
      <div className="update-profile-body">
        <h2 className="main-title mt-3 mb-3">Search for a perfect partner</h2>
        <div>
          <form action="" className="common-form">
            <div className="row">
              <div className="col-md-3">
                <label className="d-block w-100">Search For:</label>
                <div className="form-group aimc-radio">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      id="customRadio-1"
                      name="example1"
                      value="Bride"
                    />
                    <label className="custom-control-label" for="customRadio-1">
                      Bride
                    </label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      id="customRadio-2"
                      name="example1"
                      value="Groom"
                    />
                    <label className="custom-control-label" for="customRadio-2">
                      Groom
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="form-group">
                  <div className="d-flex">
                    <select className="custom-select mr-3">
                      <option selected="">Min-Age</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                    </select>
                    <select className="custom-select mr-3">
                      <option selected="">Max-Age</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                    </select>
                    <div>
                      <input
                        type="submit"
                        value="Search"
                        className="btn green-btn"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="matrimonial-container">
          <div className="row">
            <div className="col-md-3 col-sm-6 mb-3">
              <div className="card text-center border-none team-item-1">
                <div className="lab-inner">
                  <div className="lab-thumb">
                    <img
                      src="img/testimonal-1.png"
                      className="card-img-top"
                      alt="product"
                    />
                  </div>
                  <div className="lab-content">
                    <a href="#">
                      <h6 className="card-title mb-0">Meera patel </h6>
                    </a>
                    <ul className="list-unstyled">
                      <li>
                        <strong>Age:</strong> <span>32</span>
                      </li>
                      <li>
                        <strong>Education:</strong> <span>B.COM</span>
                      </li>
                      <li>
                        <strong>Loaction:</strong> <span>Delhi</span>
                      </li>
                    </ul>
                    <div className="social-share">
                      <a
                        href="member-matrimonial-profile-view.html"
                        type="button"
                      >
                        View Profile
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-3">
              <div className="card text-center border-none team-item-1">
                <div className="lab-inner">
                  <div className="lab-thumb">
                    <img
                      src="img/testimonal-1.png"
                      className="card-img-top"
                      alt="product"
                    />
                  </div>
                  <div className="lab-content">
                    <a href="#">
                      <h6 className="card-title mb-0">Meera patel </h6>
                    </a>
                    <ul className="list-unstyled">
                      <li>
                        <strong>Age:</strong> <span>32</span>
                      </li>
                      <li>
                        <strong>Education:</strong> <span>B.COM</span>
                      </li>
                      <li>
                        <strong>Loaction:</strong> <span>Delhi</span>
                      </li>
                    </ul>
                    <div className="social-share">
                      <a
                        href="member-matrimonial-profile-view.html"
                        type="button"
                      >
                        View Profile
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-3">
              <div className="card text-center border-none team-item-1">
                <div className="lab-inner">
                  <div className="lab-thumb">
                    <img
                      src="img/testimonal-1.png"
                      className="card-img-top"
                      alt="product"
                    />
                  </div>
                  <div className="lab-content">
                    <a href="#">
                      <h6 className="card-title mb-0">Meera patel </h6>
                    </a>
                    <ul className="list-unstyled">
                      <li>
                        <strong>Age:</strong> <span>32</span>
                      </li>
                      <li>
                        <strong>Education:</strong> <span>B.COM</span>
                      </li>
                      <li>
                        <strong>Loaction:</strong> <span>Delhi</span>
                      </li>
                    </ul>
                    <div className="social-share">
                      <a
                        href="member-matrimonial-profile-view.html"
                        type="button"
                      >
                        View Profile
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-3">
              <div className="card text-center border-none team-item-1">
                <div className="lab-inner">
                  <div className="lab-thumb">
                    <img
                      src="img/testimonal-1.png"
                      className="card-img-top"
                      alt="product"
                    />
                  </div>
                  <div className="lab-content">
                    <a href="#">
                      <h6 className="card-title mb-0">Meera patel </h6>
                    </a>
                    <ul className="list-unstyled">
                      <li>
                        <strong>Age:</strong> <span>32</span>
                      </li>
                      <li>
                        <strong>Education:</strong> <span>B.COM</span>
                      </li>
                      <li>
                        <strong>Loaction:</strong> <span>Delhi</span>
                      </li>
                    </ul>
                    <div className="social-share">
                      <a
                        href="member-matrimonial-profile-view.html"
                        type="button"
                      >
                        View Profile
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-3">
              <div className="card text-center border-none team-item-1">
                <div className="lab-inner">
                  <div className="lab-thumb">
                    <img
                      src="img/testimonal-1.png"
                      className="card-img-top"
                      alt="product"
                    />
                  </div>
                  <div className="lab-content">
                    <a href="#">
                      <h6 className="card-title mb-0">Meera patel </h6>
                    </a>
                    <ul className="list-unstyled">
                      <li>
                        <strong>Age:</strong> <span>32</span>
                      </li>
                      <li>
                        <strong>Education:</strong> <span>B.COM</span>
                      </li>
                      <li>
                        <strong>Loaction:</strong> <span>Delhi</span>
                      </li>
                    </ul>
                    <div className="social-share">
                      <a
                        href="member-matrimonial-profile-view.html"
                        type="button"
                      >
                        View Profile
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-3">
              <div className="card text-center border-none team-item-1">
                <div className="lab-inner">
                  <div className="lab-thumb">
                    <img
                      src="img/testimonal-1.png"
                      className="card-img-top"
                      alt="product"
                    />
                  </div>
                  <div className="lab-content">
                    <a href="#">
                      <h6 className="card-title mb-0">Meera patel </h6>
                    </a>
                    <ul className="list-unstyled">
                      <li>
                        <strong>Age:</strong> <span>32</span>
                      </li>
                      <li>
                        <strong>Education:</strong> <span>B.COM</span>
                      </li>
                      <li>
                        <strong>Loaction:</strong> <span>Delhi</span>
                      </li>
                    </ul>
                    <div className="social-share">
                      <a
                        href="member-matrimonial-profile-view.html"
                        type="button"
                      >
                        View Profile
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatrimonialDashboard;
