import React, { useContext, useState } from "react";

import { ApiDataContext } from "../Contexts/ApiData";
import Banner from "../Components/Banner";
import GalleryImageCard from "../Components/Cards/GalleryImageCard";

const Gallery = () => {
  const [showImage, setShowImage] = useState({
    show: false,
    imgIndex: 0,
  });

  const apiData = useContext(ApiDataContext);

  const pageBanner = apiData?.PageBanner;
  const gallery = apiData?.Gallery;

  return (
    <>
      {pageBanner && pageBanner.length > 0 ? (
        <Banner
          heading={pageBanner[0].PageBannerTitle}
          imgUrl={pageBanner[0].DisplayURL}
          alt={"Gallery"}
        />
      ) : (
        <></>
      )}
      {gallery && gallery.length ? (
        <section className="gallery-body" id="gallery">
          <div className="container">
            <div id="image-gallery">
              <div className="row">
                {gallery.map((image, index) => (
                  <GalleryImageCard
                    key={index}
                    heading={image?.GalleryTitle}
                    imgUrl={image?.DisplayURL}
                    onClick={() =>
                      setShowImage({
                        show: true,
                        imgIndex: index,
                      })
                    }
                  />
                ))}
              </div>
            </div>
          </div>

          <div
            className={`${
              showImage.show ? "d-flex opacity-100" : "opacity-0 d-none"
            }`}
            id="overlay"
            onClick={() => setShowImage((prvs) => ({ ...prvs, show: false }))}
          >
            {showImage.imgIndex > 0 && (
              <div
                role="button"
                id="prevButton"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowImage((prvs) => ({
                    ...prvs,
                    imgIndex: prvs.imgIndex - 1,
                  }));
                }}
              >
                <i className="fa fa-chevron-left"></i>
              </div>
            )}
            <img
              className={`${showImage.show ? "opacity-100" : "opacity-0"}`}
              src={gallery[showImage.imgIndex].DisplayURL}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
            {showImage.imgIndex < gallery.length - 1 && (
              <div
                role="button"
                id="nextButton"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowImage((prvs) => ({
                    ...prvs,
                    imgIndex: prvs.imgIndex + 1,
                  }));
                }}
              >
                <i className="fa fa-chevron-right"></i>
              </div>
            )}
            <div role="button" id="exitButton">
              <i className="fa fa-times"></i>
            </div>
          </div>
        </section>
      ) : (
        <h3 className="text-center p-4">Images not available at the moment.</h3>
      )}
    </>
  );
};

export default Gallery;
