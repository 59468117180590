import React, { useContext, useEffect, useState } from "react";
import { MemberDetails } from "../../Contexts/MemberDetailContext";
import { Spinner } from "react-bootstrap";
import EducationRow from "./EducationRow";
import { getApiResponse } from "../../Utils/AxiosRequest";
import { API_PATHS } from "../../Utils/Constants/constants";
import { useFieldArray, useForm } from "react-hook-form";
import ConditionalLoader from "../ConditionalLoader";

const EducationUpdate = ({ onSubmit }) => {
  const { education, isLoading } = useContext(MemberDetails);
  const [isSubmitting, setIsSubmiting] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const [degrees, setDegrees] = useState([]);
  const [graders, setGraders] = useState([]);
  const [isLoadingForm, setIsLoadingForm] = useState(true);

  const educationList = education.map((item) => ({
    DegreeId: item.DegreeId,
    PassingYear: item.PassingYear,
    StartDate: item.StartDate.slice(0, 10),
    EndDate: item.EndDate.slice(0, 10),
    InstitutionName: item.InstitutionName,
    FieldOfStudy: item.FieldOfStudy,
    DegreeGradeId: item.DegreeGradeId,
    DegreePercentage: item.DegreePercentage,
    DegreeDescription: item.DegreeDescription,
  }));

  const memberEducationTemplate = {
    MemberId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    DegreeId: "0",
    PassingYear: "",
    StartDate: "",
    EndDate: "",
    InstitutionName: "",
    FieldOfStudy: "",
    DegreeGradeId: "0",
    DegreePercentage: "",
    DegreeDescription: "",
    CreatedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  };

  const {
    register,
    handleSubmit,
    formState: { errors, reset, isDirty },
    setValue,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      forms: education.length ? educationList : [memberEducationTemplate],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "forms",
  });

  const addEducation = (e) => {
    e.preventDefault();
    append(memberEducationTemplate);
    // const newEducationDetails = [...educationDetails, memberEducationTemplate];
    // const newSelectedRow = newEducationDetails.length - 1;
    // setSelectedRow(newSelectedRow);
    // setEducationDetails(newEducationDetails);
  };

  const updateEducation = (data) => {
    console.log(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingForm(true);
      try {
        const degreeResponse = await getApiResponse(API_PATHS.getDegrees);
        if (degreeResponse.status === 200) {
          setDegrees(degreeResponse.data);
        }
        const gradeResponse = await getApiResponse(API_PATHS.getGrades);
        if (gradeResponse.status === 200) {
          setGraders(gradeResponse.data);
        }
        // setValue({
        //   forms: education.length ? educationList : [memberEducationTemplate],
        // });
      } catch (error) {
        console.error("Error", error);
      }
      setIsLoadingForm(false);
    };
    fetchData();
  }, []);

  return (
    <ConditionalLoader isLoading={isLoadingForm || isLoading}>
      <form onSubmit={onSubmit}>
        <div className="tab-pane">
          <h4 className="sub-title mb-4">Education Detail</h4>
          <div className="" id="add-education-detail">
            {fields.map((item, index) => (
              <EducationRow
                key={index}
                index={index}
                register={register}
                isSubmitting={isSubmitting}
                grades={graders}
                degrees={degrees}
                errors={errors?.forms?.[index]}
                isDirty={isDirty}
                item={item}
                lastItem={index === fields.length - 1}
                setValue={setValue}
                getValues={getValues}
                remove={remove}
              />
            ))}
          </div>
          {/* <div className="" id="add-education-detail">
            <div className="accordion">
              {educationDetails.map((item, index) => (
                <EducationTemp />
              ))}
            </div>
          </div> */}

          <div>
            <button
              className="btn green-btn mt-4"
              id="add-education-btn"
              onClick={addEducation}
            >
              <i className="fas fa-plus"></i> Add Row
            </button>
          </div>
          <ul class="w-full list-inline text-right d-flex justify-content-end">
            <li>
              {isSubmitting ? (
                <button className="btn green-btn px-5 cursor-na" disabled>
                  <Spinner size="sm" />
                </button>
              ) : (
                <input
                  type="submit"
                  className="btn green-btn"
                  value="Save"
                  onClick={handleSubmit(updateEducation)}
                />
              )}
            </li>
          </ul>
        </div>
      </form>
    </ConditionalLoader>
  );
};

export default EducationUpdate;
