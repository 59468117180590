import React from "react";

const ErrorsList = ({ isDirty, errors }) => {
  return (
    <div className="col-md-12 row pb-4">
      {isDirty &&
        errors &&
        Object.values(errors).map((error, index) => (
          <div className="col-sm-6" key={index}>
            <p className="text-danger text-left p-0 m-0">{`* ${error.message}`}</p>
          </div>
        ))}
    </div>
  );
};

export default ErrorsList;
