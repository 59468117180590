import React, { useContext, useEffect, useState } from "react";
import { MemberDetails } from "../../Contexts/MemberDetailContext";
import { getApiResponse, postData } from "../../Utils/AxiosRequest";
import { API_PATHS, URL_PATH } from "../../Utils/Constants/constants";
import { useForm } from "react-hook-form";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ConditionalLoader from "../ConditionalLoader";
import Modal from "../Modal";
import ErrorsList from "../ErrorsList";

const PersonalUpdate = () => {
  const { memberDetails } = useContext(MemberDetails);
  const [genders, setGenders] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [nationalities, setNationalities] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState({
    heading: "",
    description: "",
  });
  const [apiError, setApiError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors, reset, isDirty },
    setValue,
  } = useForm();

  const phoneValidate = (v) => {
    const regexExp = /^\d+$/;
    return regexExp.test(v) || "Please enter a valid mobile number";
  };

  const checkSpecialCharacters = (v, name) => {
    return v != 0 || `Please select ${name}`;
  };

  const formatReqBody = (data) => {
    const formData = { ...data };
    const reqBody = {};

    Object.keys(formData).forEach((key) => {
      let value = formData[key];
      if (key === "Email" || key === "PhoneNumber") {
        reqBody[key] = memberDetails[key];
      } else if (key === "UploadedFile") {
        const fileArray = Array.from(value);
        reqBody[key] = fileArray;
      } else if (key === "UploadedFile") {
        const fileArray = Array.from(value);
        reqBody[key] = fileArray;
      } else if (
        key === "GenderId" ||
        key === "CurrentDesignationId" ||
        key === "NationalityId" ||
        key === "CountryId" ||
        key === "StateId" ||
        key === "DistrictId"
      ) {
        if (value === "0") {
          reqBody[key] = "";
        } else {
          reqBody[key] = value;
        }
      } else if (key === "DOB") {
        const date = new Date(value).toLocaleDateString("en", "dd-mm-yyyy");
        reqBody[key] = date;
      } else if (typeof value === "string" || typeof value === "number") {
        reqBody[key] = value.toString().trim();
      }
    });
    return reqBody;
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const reqBody = formatReqBody(data);
    console.log(reqBody);

    try {
      const response = await postData(API_PATHS.updateMember, reqBody, {
        formData: true,
        bearer: true,
      });
      if (response.status === 200) {
        const memberId = response?.data?.Response?.MemberId;
        if (response.data.IsSuccess && memberId) {
          setShowModal(true);
          setMessage({
            heading: "Updated Successful",
            description: "Personal details updated successfully",
          });
        } else {
          setShowModal(true);
          setMessage({
            heading: "Something went wrong",
            description: response.data.Message,
          });
        }
      } else if (response.status === 400) {
        setShowModal(true);
        setMessage({
          heading: "Something went wrong",
          description: "Please check all the inputs and resubmit",
        });
      }
    } catch (error) {
      console.error(error);
      setShowModal(true);
      if (error.status === 400) {
        setMessage({
          heading: "Something went wrong",
          description: "Please check all the inputs and resubmit",
        });
      } else {
        setMessage({
          heading: "Something went wrong",
          description: "Please try again after some time",
        });
      }
    }
    setIsSubmitting(false);
  };

  const pincodeValidate = (v) => {
    const regexExp = /^\d+$/;
    return regexExp.test(v) || "Please enter a valid Pin Code";
  };

  const closePopup = () => {
    setMessage({
      heading: "",
      description: "",
    });
    setShowModal(false);
  };

  const mapDataFromAPI = (key) => {
    if (key === "DOB") {
      setValue(key, memberDetails[key]?.slice(0, 10));
    } else if (memberDetails[key] === "00000000-0000-0000-0000-000000000000") {
      setValue(key, 0);
    } else {
      setValue(key, memberDetails[key] ?? "");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getApiResponse(API_PATHS.getNationalities);
        if (response.status === 200) {
          setNationalities(response.data);
        }
        const genderResponse = await getApiResponse(API_PATHS.getGenders);
        if (genderResponse.status === 200) {
          setGenders(genderResponse.data ?? []);
        }
        const designationResponse = await getApiResponse(
          API_PATHS.getDesignations
        );
        if (designationResponse.status === 200) {
          setDesignations(designationResponse.data ?? []);
        }
        const countriesResponse = await getApiResponse(API_PATHS.getCountries);
        if (countriesResponse.status === 200) {
          setCountries(countriesResponse.data ?? []);
        }
        const statesResponse = await getApiResponse(API_PATHS.getStates);
        if (statesResponse.status === 200) {
          setStates(statesResponse.data ?? []);
        }
        const districtResponse = await getApiResponse(API_PATHS.getDisticts);
        if (districtResponse.status === 200) {
          setDistricts(districtResponse.data ?? []);
        }
      } catch (error) {
        console.error("Error", error);
        setApiError("Something went wrong please try refreshing.");
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchMemberDetails = async () => {
      const keys = Object.keys(memberDetails);
      if (keys.length === 0) {
        const response = await getApiResponse(API_PATHS.viewMember, true);
        if (response.status === 200) {
          const memberDetailResponse = response?.data?.MemberDetails ?? {};
          Object.keys(memberDetailResponse).forEach(mapDataFromAPI);
        }
      } else {
        keys.forEach(mapDataFromAPI);
      }
    };
    fetchMemberDetails();
  }, [memberDetails]);
  console.log(Object.keys(errors));

  return (
    <ConditionalLoader isLoading={isLoading}>
      <div className="tab-pane active mb-2">
        {apiError && <p className="text-white p-2 w-full ">{apiError}</p>}
        <h4 className="sub-title mb-6">Personal Detail</h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <input
                  type="email"
                  className={`form-control ${
                    errors.Email && isDirty
                      ? "red-placeholder border border-danger"
                      : ""
                  }`}
                  placeholder={
                    errors.Email && isDirty ? errors.Email.message : "Email"
                  }
                  autoComplete="off"
                  {...register("Email", {
                    required: "Email is required",
                  })}
                  disabled={true}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control ${
                    errors.PhoneNumber && isDirty
                      ? "red-placeholder border border-danger"
                      : ""
                  }`}
                  placeholder={
                    errors.PhoneNumber && isDirty
                      ? errors.PhoneNumber.message
                      : "Phone No."
                  }
                  {...register("PhoneNumber", {
                    required: "Please enter your phone number",
                    maxLength: {
                      value: 10,
                      message: "Please enter a valid mobile number",
                    },
                    minLength: {
                      value: 10,
                      message: "Please enter a valid mobile number",
                    },
                    validate: phoneValidate,
                  })}
                  disabled={true}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control ${
                    errors.FirstName && isDirty
                      ? "red-placeholder border border-danger"
                      : ""
                  }`}
                  placeholder={
                    errors.FirstName && isDirty
                      ? errors.FirstName.message
                      : "First Name"
                  }
                  autoComplete="off"
                  {...register("FirstName", {
                    required: "First Name is required",
                  })}
                  disabled={isSubmitting}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control ${
                    errors.LastName && isDirty
                      ? "red-placeholder border border-danger"
                      : ""
                  }`}
                  placeholder={
                    errors.LastName && isDirty
                      ? errors.LastName.message
                      : "Last Name"
                  }
                  autoComplete="off"
                  {...register("LastName", {
                    required: "Last Name is required",
                  })}
                  disabled={isSubmitting}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control ${
                    errors.FatherName && isDirty
                      ? "red-placeholder border border-danger"
                      : ""
                  }`}
                  placeholder={
                    errors.FatherName && isDirty
                      ? errors.FatherName.message
                      : "Father’s Name"
                  }
                  autoComplete="off"
                  {...register("FatherName", {
                    required: "Father's Name is required",
                  })}
                  disabled={isSubmitting}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <select
                  className={`custom-select ${
                    errors.GenderId && isDirty
                      ? "red-placeholder border border-danger"
                      : ""
                  }`}
                  {...register("GenderId", {
                    validate: (v) => checkSpecialCharacters(v, "Gender"),
                  })}
                  disabled={isSubmitting}
                >
                  <option key={0} value={0}>
                    Gender
                  </option>
                  {genders.length > 0 &&
                    genders.map((item) => (
                      <option key={item.GenderId} value={item.GenderId}>
                        {item.GenderDesc.toUpperCase()}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control ${
                    errors.DOB && isDirty
                      ? "red-placeholder border border-danger"
                      : ""
                  }`}
                  placeholder={
                    errors.DOB && isDirty ? errors.DOB.message : "DOB"
                  }
                  {...register("DOB", {
                    max: {
                      value: new Date().toLocaleDateString(),
                      message: "Please select a valid DOB",
                    },
                  })}
                  onFocus={(e) => {
                    e.target.type = "date";
                  }}
                  onBlur={(e) => {
                    e.target.type = "text";
                  }}
                  disabled={isSubmitting}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <select
                  className={`custom-select ${
                    errors.CurrentDesignationId && isDirty
                      ? "red-placeholder border border-danger"
                      : ""
                  }`}
                  placeholder={
                    errors.CurrentDesignationId && isDirty
                      ? errors.CurrentDesignationId.message
                      : "Designation"
                  }
                  {...register("CurrentDesignationId", {
                    validate: (v) => checkSpecialCharacters(v, "Designation"),
                    required: "Please select Designation",
                  })}
                  disabled={isSubmitting}
                >
                  <option key={0} value={0}>
                    Designation
                  </option>
                  {designations.length > 0 &&
                    designations.map((item) => (
                      <option
                        key={item.DesignationId}
                        value={item.DesignationId}
                      >
                        {item.DesignationDesc.toUpperCase()}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <select
                  className={`custom-select ${
                    errors.NationalityId && isDirty
                      ? "red-placeholder border border-danger"
                      : ""
                  }`}
                  placeholder={
                    errors.NationalityId && isDirty
                      ? errors.NationalityId.message
                      : "Nationality"
                  }
                  {...register("NationalityId", {
                    validate: (v) => checkSpecialCharacters(v, "Nationality"),
                  })}
                  disabled={isSubmitting}
                >
                  <option key={0} value={0}>
                    Nationality
                  </option>
                  {nationalities.length > 0 &&
                    nationalities.map((item) => (
                      <option
                        key={item.NationalityId}
                        value={item.NationalityId}
                      >
                        {item.Country.toUpperCase()}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <select
                  className={`custom-select ${
                    errors.MemberType && isDirty
                      ? "red-placeholder border border-danger"
                      : ""
                  }`}
                  placeholder={
                    errors.MemberType && isDirty
                      ? errors.MemberType.message
                      : "Member Type"
                  }
                  {...register("MemberType")}
                  disabled={isSubmitting}
                >
                  <option value="a">Active Member</option>
                  <option value="v">Volunteer</option>
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control ${
                    errors.AddressLine1 && isDirty
                      ? "red-placeholder border border-danger"
                      : ""
                  }`}
                  placeholder={
                    errors.AddressLine1 && isDirty
                      ? errors.AddressLine1.message
                      : "Permanent Address Line-1"
                  }
                  {...register("AddressLine1", {
                    required: "Please enter Address",
                  })}
                  disabled={isSubmitting}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control ${
                    errors.AddressLine2 && isDirty
                      ? "red-placeholder border border-danger"
                      : ""
                  }`}
                  placeholder={
                    errors.AddressLine2 && isDirty
                      ? errors.AddressLine2.message
                      : "Permanent Address Line-2"
                  }
                  {...register("AddressLine2")}
                  disabled={isSubmitting}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control ${
                    errors.CityName && isDirty
                      ? "red-placeholder border border-danger"
                      : ""
                  }`}
                  placeholder={
                    errors.CityName && isDirty
                      ? errors.CityName.message
                      : "City"
                  }
                  {...register("CityName", {
                    required: "Please enter your city",
                  })}
                  disabled={isSubmitting}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <select
                  className={`custom-select ${
                    errors.CountryId && isDirty
                      ? "red-placeholder border border-danger"
                      : ""
                  }`}
                  placeholder={
                    errors.CountryId && isDirty
                      ? errors.CountryId.message
                      : "Country"
                  }
                  {...register("CountryId", {
                    validate: (v) => checkSpecialCharacters(v, "Contry"),
                  })}
                  disabled={isSubmitting}
                >
                  <option key={0} value={0}>
                    Country
                  </option>
                  {countries.length > 0 &&
                    countries.map((item) => (
                      <option key={item.CountryId} value={item.CountryId}>
                        {item.Name.toUpperCase()}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <select
                  className={`custom-select ${
                    errors.StateId && isDirty
                      ? "red-placeholder border border-danger"
                      : ""
                  }`}
                  placeholder={
                    errors.StateId && isDirty
                      ? errors.StateId.message
                      : "Country"
                  }
                  {...register("StateId", {
                    validate: (v) => checkSpecialCharacters(v, "State"),
                  })}
                  disabled={isSubmitting}
                >
                  <option key={0} value={0}>
                    State
                  </option>
                  {states.length > 0 &&
                    states.map((item) => (
                      <option key={item.StateId} value={item.StateId}>
                        {item.Name.toUpperCase()}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <select
                  className={`custom-select ${
                    errors.DistrictId && isDirty
                      ? "red-placeholder border border-danger"
                      : ""
                  }`}
                  placeholder={
                    errors.DistrictId && isDirty
                      ? errors.DistrictId.message
                      : "Country"
                  }
                  {...register("DistrictId", {
                    validate: (v) => checkSpecialCharacters(v, "District"),
                  })}
                  disabled={isSubmitting}
                >
                  <option key={0} value={0}>
                    District
                  </option>
                  {districts.length > 0 &&
                    districts.map((item) => (
                      <option key={item.CityId} value={item.CityId}>
                        {item.Name.toUpperCase()}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control ${
                    errors.Pincode && isDirty
                      ? "red-placeholder border border-danger"
                      : ""
                  }`}
                  placeholder={
                    errors.Pincode && isDirty
                      ? errors.Pincode.message
                      : "Pincode"
                  }
                  {...register("Pincode", {
                    validate: pincodeValidate,
                  })}
                  disabled={isSubmitting}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control ${
                    errors.RecommendedBy && isDirty
                      ? "red-placeholder border border-danger"
                      : ""
                  }`}
                  placeholder={
                    errors.RecommendedBy && isDirty
                      ? errors.RecommendedBy.message
                      : "Recommended By"
                  }
                  {...register("RecommendedBy", {
                    required: "Please enter Recommended By",
                  })}
                  disabled={isSubmitting}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    accept="image/*"
                    id="customFile"
                    {...register("UploadedFile")}
                    disabled={isSubmitting}
                  />
                  <label className="custom-file-label" htmlFor="customFile">
                    Upload Your Image
                  </label>
                </div>
              </div>
            </div>
            <ErrorsList isDirty={isDirty} errors={errors} />
            <ul className="w-full list-inline text-right d-flex justify-content-end">
              <li>
                {isSubmitting ? (
                  <button className="btn green-btn px-5 cursor-na" disabled>
                    <Spinner size="sm" />
                  </button>
                ) : (
                  <input type="submit" className="btn green-btn" value="Save" />
                )}
              </li>
            </ul>
          </div>
        </form>
        <Modal
          showModal={showModal}
          closeModal={() => {
            setShowModal(false);
          }}
          heading={message.heading}
          summary={message.description}
        />
      </div>
    </ConditionalLoader>
  );
};

export default PersonalUpdate;
