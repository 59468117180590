import React, { useContext, useState } from "react";
import { MemberDetails } from "../../Contexts/MemberDetailContext";

const WorkExpUpdate = ({ onSubmit }) => {
  const workExpTemplate = {
    MemberId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    StartDate: "2024-11-21T19:15:45.715Z",
    EndDate: "2024-11-21T19:15:45.715Z",
    EmploymentNatureId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    JobTitle: "string",
    CompanyName: "string",
    JobLocation: "string",
    LeavingReason: "string",
    CreatedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  };
  const { workExperience, isLoading } = useContext(MemberDetails);
  const [workExpDetails, setWorkExpDetails] = useState(
    workExperience.length ? workExpDetails : [workExpTemplate]
  );

  return (
    <form onSubmit={onSubmit}>
      <div className="tab-pane">
        <h4 className="sub-title mb-4">Work Exp Details</h4>
        {workExpDetails.map((item) => (
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Job Title"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Company Name"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  onfocus="(this.type='date')"
                  placeholder="Start Date"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  onfocus="(this.type='date')"
                  placeholder="End Date"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <textarea
                  name=""
                  id=""
                  className="form-control"
                  placeholder="Responsibilities"
                ></textarea>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <textarea
                  name=""
                  id=""
                  className="form-control"
                  placeholder="Skills Acquired"
                ></textarea>
              </div>
            </div>
          </div>
        ))}
        <div>
          <a href="#" className="btn green-btn mt-4" id="add-education-btn">
            <i className="fas fa-plus"></i> Add Row
          </a>
        </div>
        <ul className="d-flex justify-content-end list-inline">
          <li>
            <button type="button" className="btn green-btn">
              Submit
            </button>
          </li>
        </ul>
      </div>
    </form>
  );
};

export default WorkExpUpdate;
