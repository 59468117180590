import React from "react";
import { getDateTime, getLimitHtmlContent } from "../../Utils/format";

const NewsCard = ({
  imgUrl,
  date,
  heading,
  summary,
  subContent,
  readmore,
  dFormat,
}) => {
  const dateTime = date ? getDateTime(date, dFormat) : undefined;

  return (
    <div className="latest-news-col mb-4">
      <div className="news-updates-itam">
        <div className="news-updates-itam-body">
          <div className="news-img">
            <img src={imgUrl} alt="slider" />
            {dateTime && (
              <div className="news-date">
                <span>{dateTime}</span>
              </div>
            )}
          </div>
          <div className="news-body">
            <h3>{getLimitHtmlContent(heading, 70)}</h3>
            <div
              className="mw-100 hide-child"
              dangerouslySetInnerHTML={{
                __html: getLimitHtmlContent(summary, 60),
              }}
            ></div>
            <p>
              <i>{subContent}</i>
            </p>
            {readmore && (
              <a
                title="Read More pointer"
                className="main-link"
                tabIndex="0"
                onClick={(e) => {
                  e.preventDefault();
                  readmore();
                }}
              >
                <i className="fas fa-arrow-right"></i> Read More
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsCard;
