import React, { useEffect, useState } from "react";
import Modal from "../../Components/Modal";
import { useForm } from "react-hook-form";

const MemberAddJob = () => {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState({
    heading: "",
    description: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors, reset, isDirty },
  } = useForm();

  const closePopup = () => {
    setMessage({
      heading: "",
      description: "",
    });
    setShowModal(false);
  };

  const phoneValidate = (v) => {
    const regexExp = /^\d+$/;
    return regexExp.test(v) || "Please enter a valid mobile number";
  };

  const addJob = async () => {
    // handle add job
  };

  useEffect(() => {}, []);

  return (
    <div className="add-job-body">
      <div className="common-form">
        <h2 className="main-title mt-3 mb-3">Add New Job</h2>
        <form action="">
          <div id="job-form-container">
            <div className="row add-job-row">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${
                      errors.Designation && isDirty
                        ? "red-placeholder border border-danger"
                        : ""
                    }`}
                    placeholder={
                      errors.Designation && isDirty
                        ? errors.Designation.message
                        : "Designation"
                    }
                    autoComplete="off"
                    {...register("Designation", {
                      required: "Please enter Designation",
                    })}
                    disabled={isSubmitting}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${
                      errors.CompanyName && isDirty
                        ? "red-placeholder border border-danger"
                        : ""
                    }`}
                    placeholder={
                      errors.CompanyName && isDirty
                        ? errors.CompanyName.message
                        : "Company Name"
                    }
                    autoComplete="off"
                    {...register("CompanyName", {
                      required: "Please enter Company Name",
                    })}
                    disabled={isSubmitting}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${
                      errors.JobLocation && isDirty
                        ? "red-placeholder border border-danger"
                        : ""
                    }`}
                    placeholder={
                      errors.JobLocation && isDirty
                        ? errors.JobLocation.message
                        : "Job Location"
                    }
                    autoComplete="off"
                    {...register("JobLocation", {
                      required: "Please enter Designation",
                    })}
                    disabled={isSubmitting}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${
                      errors.PhoneNumber && isDirty
                        ? "red-placeholder border border-danger"
                        : ""
                    }`}
                    placeholder={
                      errors.PhoneNumber && isDirty
                        ? errors.PhoneNumber.message
                        : "Phone No."
                    }
                    {...register("PhoneNumber", {
                      required: "Please enter your phone number",
                      maxLength: {
                        value: 10,
                        message: "Please enter a valid mobile number",
                      },
                      minLength: {
                        value: 10,
                        message: "Please enter a valid mobile number",
                      },
                      validate: phoneValidate,
                    })}
                    disabled={isSubmitting}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${
                      errors.ValidTill && isDirty
                        ? "red-placeholder border border-danger"
                        : ""
                    }`}
                    placeholder={
                      errors.ValidTill && isDirty
                        ? errors.ValidTill.message
                        : "Valid Till"
                    }
                    {...register("ValidTill", {
                      max: {
                        value: new Date().toLocaleDateString(),
                        message: "Please select a Valid Date",
                      },
                    })}
                    onFocus={(e) => {
                      e.target.type = "date";
                    }}
                    onBlur={(e) => {
                      e.target.type = "text";
                    }}
                    disabled={isSubmitting}
                  />
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
          <div>
            <button className="btn green-btn mt-4" onClick={addJob}>
              Submit
            </button>
          </div>
        </form>
      </div>
      <Modal
        showModal={showModal}
        closeModal={() => {
          setShowModal(false);
        }}
        heading={message.heading}
        summary={message.description}
      />
    </div>
  );
};

export default MemberAddJob;
