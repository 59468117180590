export const createSession = (token, id, rememberMe) => {
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("id", id);
  if (rememberMe) {
    localStorage.setItem("token", token);
    localStorage.setItem("id", id);
  }
};

export const clearSession = () => {
  sessionStorage.clear();
  localStorage.clear();
};

export const getSessionIdToken = () => {
  const sessionId = sessionStorage.getItem("id");
  const sessionToken = sessionStorage.getItem("token");
  const localId = sessionStorage.getItem("id");
  const localToken = sessionStorage.getItem("token");
  const id = sessionId ?? localId ?? "";
  const token = sessionToken ?? localToken ?? "";
  return { id, token };
};
