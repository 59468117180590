import React from "react";
import { Route, Routes } from "react-router-dom";
import ContactUs from "../Pages/ContactUs";
import Gallery from "../Pages/Gallery";
import SocialResponsibility from "../Pages/SocialResponsibility";
import About from "../Pages/About";
import History from "../Pages/History";
import Home from "../Pages/Home";
import Events from "../Pages/Events";
import News from "../Pages/News";
import Donation from "../Pages/Donation";
import Jobs from "../Pages/Jobs";
import JoinUs from "../Pages/JoinUs";
import NewsDetails from "../Pages/NewsDetails";
import EventDetails from "../Pages/EventDetails";
import NotFound from "../Pages/NotFound";
import Register from "../Pages/Register";
import Login from "../Pages/Login";
import Payment from "../Pages/Payment";
import { URL_PATH } from "../Utils/Constants/constants";
import ProtectedRoutes from "./ProtectedRoutes";
import MembershipProfile from "../Pages/Member/MembershipProfile";
import MembershipLayout from "../Layout/MembershipLayout";
import MemberUpdateProfile from "../Pages/Member/MemberUpdateProfile";
import MemberAddJob from "../Pages/Member/MemberAddJob";
import MemberJobDashboard from "../Pages/Member/MemberJobDashboard";
import MemberHome from "../Pages/Member/MemberHome";
import MemberSearch from "../Pages/Member/MemberSearch";
import CreateProfile from "../Pages/Member/CreateProfile";
import MatrimonialDashboard from "../Pages/Member/MatrimonialDashboard";
import MatrimonialProfile from "../Pages/Member/MatrimonialProfile";
// import ForgotPassword from "../Pages/ForgotPassword";
// import Dashboard from "../Pages/Dashboard";

const AllRoutes = () => {
  return (
    <Routes>
      {/* <OpenRoutes /> */}
      {/* <ProtectedRoutes /> */}
      <Route path={URL_PATH.home} element={<Home />} />
      <Route path={URL_PATH.history} element={<History />} />
      <Route path={URL_PATH.about} element={<About />} />
      <Route path={URL_PATH.social} element={<SocialResponsibility />} />
      <Route path={URL_PATH.gallery} element={<Gallery />} />
      <Route path={URL_PATH.contact} element={<ContactUs />} />
      <Route path={URL_PATH.newsbyid + "/:id"} element={<NewsDetails />} />
      <Route path={URL_PATH.news} element={<News />} />
      <Route path={URL_PATH.eventbyid + "/:id"} element={<EventDetails />} />
      <Route path={URL_PATH.events} element={<Events />} />
      <Route path={URL_PATH.donation} element={<Donation />} />
      <Route path={URL_PATH.jobs} element={<Jobs />} />
      <Route path={URL_PATH.membership} element={<Register />} />
      <Route path={URL_PATH.login} element={<Login />} />
      {/* <Route path={URL_PATH.forgotpassword} element={<ForgotPassword />} /> */}
      <Route path={URL_PATH.payment} element={<Payment />} />
      <Route element={<ProtectedRoutes />}>
        <Route element={<MembershipLayout />}>
          <Route path={URL_PATH.memberhome} element={<MemberHome />} />
          <Route
            path={URL_PATH.memberprofile}
            element={<MembershipProfile />}
          />
          <Route
            path={URL_PATH.memberprofileedit}
            element={<MemberUpdateProfile />}
          />
          <Route path={URL_PATH.searchmember} element={<MemberSearch />} />
          <Route
            path={URL_PATH.memberjobdashboard}
            element={<MemberJobDashboard />}
          />
          <Route path={URL_PATH.memberaddjob} element={<MemberAddJob />} />
          <Route path={URL_PATH.createprofile} element={<CreateProfile />} />
          <Route
            path={URL_PATH.matrimonialdahboard}
            element={<MatrimonialDashboard />}
          />
          <Route
            path={URL_PATH.matrimonialprofile}
            element={<MatrimonialProfile />}
          />
        </Route>
      </Route>
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default AllRoutes;
