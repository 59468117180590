import React, { useContext } from "react";

import { ApiDataContext } from "../Contexts/ApiData";

import donate from "../Assets/img/donate.png";
import Banner from "../Components/Banner";
import ButtonSlider from "../Components/Carousel/ButtonSlider";
import UEvenetCard from "../Components/Cards/UEvenetCard";
import DonationForm from "../Components/Forms/DonationForm";

const Donation = () => {
  const apiData = useContext(ApiDataContext);

  const pageBanner = apiData?.PageBanner;
  const donations = apiData?.Donations;
  const donationSubContent = apiData?.CMSPage?.DonationSubContent;
  const donationContent = apiData?.CMSPage?.DonationContent;

  return (
    <>
      {pageBanner && pageBanner.length ? (
        <Banner
          heading={pageBanner[0].PageBannerTitle}
          imgUrl={pageBanner[0].DisplayURL}
          alt={"Donation"}
        />
      ) : (
        <></>
      )}
      <section className="aimc-history">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="float-className">
                <div className="about-img">
                  <img src={donate} alt="About-us" />
                </div>
              </div>
              <div
                className="html_render"
                dangerouslySetInnerHTML={{ __html: donationContent }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      {donations && donations.length ? (
        <section className="latest-news">
          <div className="container">
            <h2 className="main-title mb-3">Donation program</h2>
            <div className="latest-news-row">
              <ButtonSlider>
                <>
                  {donations.map((event, index) => (
                    <UEvenetCard
                      key={index}
                      imgUrl={event?.DisplayURL}
                      date={event?.DonationDate}
                      heading={event?.DonationName}
                      summary={event?.DonationContent}
                      // url={event.url}
                      // location={event.location}
                      className="col-lg-12 mt-4"
                    />
                  ))}
                </>
              </ButtonSlider>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
      <section className="donation-form">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="donation-form-detail">
                <div
                  className="html_render"
                  dangerouslySetInnerHTML={{ __html: donationSubContent }}
                ></div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="common-form donation-form-bg">
                <DonationForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Donation;
