import React, { useEffect, useState } from "react";
import { getApiResponse, postData } from "../Utils/AxiosRequest";
import { API_PATHS, URL_PATH } from "../Utils/Constants/constants";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Modal from "../Components/Modal";
import { Spinner } from "react-bootstrap";

const Register = () => {
  const [nationalities, setNationalities] = useState([]);
  const [genders, setGenders] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState({
    heading: "",
    description: "",
  });
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, reset, isDirty },
  } = useForm();

  const formatReqBody = (data) => {
    const formData = { ...data };
    const reqBody = {};
    Object.keys(formData).forEach((key) => {
      let value = formData[key];
      if (key === "UploadedFile") {
        const fileArray = Array.from(value);
        reqBody[key] = fileArray;
      } else if (
        key === "GenderId" ||
        key === "CurrentDesignationId" ||
        key === "NationalityId" ||
        key === "CountryId" ||
        key === "StateId" ||
        key === "DistrictId"
      ) {
        if (value === "0") {
          reqBody[key] = "";
        } else {
          reqBody[key] = value;
        }
      } else if (key === "DOB") {
        const date = new Date(value).toLocaleDateString("en", "dd-mm-yyyy");
        reqBody[key] = date;
      } else {
        reqBody[key] = value.trim();
      }
    });
    return reqBody;
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const reqBody = formatReqBody(data);
    try {
      const response = await postData(API_PATHS.createMember, reqBody, {
        formData: true,
      });
      if (response.status === 200) {
        const memberId = response?.data?.Response?.MemberId;
        if (response.data.IsSuccess && memberId) {
          navigate(URL_PATH.payment, {
            state: {
              MemberId: memberId,
            },
          });
          reset();
        } else if (response.data.IsSuccess && !memberId) {
          setShowModal(true);
          setMessage({
            heading: "Registration Successful",
            description: "Please login to complete payment",
          });
        } else {
          setShowModal(true);
          setMessage({
            heading: "Something went wrong",
            description: response.data.Message,
          });
        }
      }
    } catch (error) {
      console.error(error);
      setShowModal(true);
      if (error.status === 400) {
        setMessage({
          heading: "Something went wrong",
          description: "Please check all the inputs and resubmit",
        });
      } else {
        setMessage({
          heading: "Something went wrong",
          description: "Please try again after some time",
        });
      }
    }
    setIsSubmitting(false);
  };

  const pincodeValidate = (v) => {
    const regexExp = /^\d+$/;
    return regexExp.test(v) || "Please enter a valid Pin Code";
  };

  const phoneValidate = (v) => {
    const regexExp = /^\d+$/;
    return regexExp.test(v) || "Please enter a valid mobile number";
  };

  const passwordValidator = (v) => {
    const regexExp =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    return (
      regexExp.test(v) ||
      "Password should contain atlease 1 small and 1 capital letter, atlease 1 number and atlease 1 symbol"
    );
  };

  const closePopup = () => {
    setMessage({
      heading: "",
      description: "",
    });
    setShowModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getApiResponse(API_PATHS.getNationalities);
        if (response.status === 200) {
          setNationalities(response.data);
        }
        const genderResponse = await getApiResponse(API_PATHS.getGenders);
        if (genderResponse.status === 200) {
          setGenders(genderResponse.data ?? []);
        }
        const designationResponse = await getApiResponse(
          API_PATHS.getDesignations
        );
        if (designationResponse.status === 200) {
          setDesignations(designationResponse.data ?? []);
        }
        const countriesResponse = await getApiResponse(API_PATHS.getCountries);
        if (countriesResponse.status === 200) {
          setCountries(countriesResponse.data ?? []);
        }
        const statesResponse = await getApiResponse(API_PATHS.getStates);
        if (statesResponse.status === 200) {
          setStates(statesResponse.data ?? []);
        }
        const districtResponse = await getApiResponse(API_PATHS.getDisticts);
        if (districtResponse.status === 200) {
          setDistricts(districtResponse.data ?? []);
        }
      } catch (error) {
        console.error("Error", error);
      }
    };
    fetchData();
  }, []);

  return (
    <section className="main-login">
      <div className="main-login-bg">
        <div className="member-ship-form">
          <div className="container">
            <div className="member-ship-title">
              <h2 className="main-title">Join All India Momin Conference</h2>
              <p>
                Fill the form below to join us, If you are found eligible, you
                will be contacted by one of our team members.
              </p>
            </div>
            <div className="common-form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.FirstName && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                        }`}
                        placeholder={
                          errors.FirstName && isDirty
                            ? errors.FirstName.message
                            : "First Name"
                        }
                        autoComplete="off"
                        {...register("FirstName", {
                          required: "First Name is required",
                        })}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.LastName && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                        }`}
                        placeholder={
                          errors.LastName && isDirty
                            ? errors.LastName.message
                            : "Last Name"
                        }
                        autoComplete="off"
                        {...register("LastName", {
                          required: "Last Name is required",
                        })}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="email"
                        className={`form-control ${
                          errors.Email && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                        }`}
                        placeholder={
                          errors.Email && isDirty
                            ? errors.Email.message
                            : "Email"
                        }
                        autoComplete="off"
                        {...register("Email", {
                          required: "Email is required",
                        })}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="password"
                        className={`form-control ${
                          errors.Password && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                        }`}
                        placeholder={
                          errors.Password && isDirty
                            ? errors.Password.message
                            : "Password"
                        }
                        autoComplete="off"
                        {...register("Password", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message: "Password should be atleast 8 characters",
                          },
                          maxLength: {
                            value: 32,
                            message:
                              "Password can not be greater than 32 characters",
                          },
                          validate: passwordValidator,
                        })}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.FatherName && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                        }`}
                        placeholder={
                          errors.FatherName && isDirty
                            ? errors.FatherName.message
                            : "Father’s Name"
                        }
                        autoComplete="off"
                        {...register("FatherName", {
                          required: "Father's Name is required",
                        })}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <select
                        className={`custom-select ${
                          errors.GenderId && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                        }`}
                        defaultValue={0}
                        {...register("GenderId")}
                        disabled={isSubmitting}
                      >
                        <option key={0} value={0}>
                          Gender
                        </option>
                        {genders.length > 0 &&
                          genders.map((item) => (
                            <option key={item.GenderId} value={item.GenderId}>
                              {item.GenderDesc.toUpperCase()}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.DOB && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                        }`}
                        placeholder={
                          errors.DOB && isDirty ? errors.DOB.message : "DOB"
                        }
                        {...register("DOB", {
                          max: {
                            value: new Date().toLocaleDateString(),
                            message: "Please select a valid DOB",
                          },
                        })}
                        onFocus={(e) => {
                          e.target.type = "date";
                        }}
                        onBlur={(e) => {
                          e.target.type = "text";
                        }}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <select
                        className={`custom-select ${
                          errors.CurrentDesignationId && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                        }`}
                        placeholder={
                          errors.CurrentDesignationId && isDirty
                            ? errors.CurrentDesignationId.message
                            : "Designation"
                        }
                        defaultValue={0}
                        {...register("CurrentDesignationId", {
                          required: "Please select Designation",
                        })}
                        disabled={isSubmitting}
                      >
                        <option key={0} value={0}>
                          Designation
                        </option>
                        {designations.length > 0 &&
                          designations.map((item) => (
                            <option
                              key={item.DesignationId}
                              value={item.DesignationId}
                            >
                              {item.DesignationDesc.toUpperCase()}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <select
                        className={`custom-select ${
                          errors.NationalityId && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                        }`}
                        placeholder={
                          errors.NationalityId && isDirty
                            ? errors.NationalityId.message
                            : "Nationality"
                        }
                        defaultValue={0}
                        {...register("NationalityId")}
                        disabled={isSubmitting}
                      >
                        <option key={0} value={0}>
                          Nationality
                        </option>
                        {nationalities.length > 0 &&
                          nationalities.map((item) => (
                            <option
                              key={item.NationalityId}
                              value={item.NationalityId}
                            >
                              {item.Country.toUpperCase()}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <select
                        className={`custom-select ${
                          errors.MemberType && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                        }`}
                        placeholder={
                          errors.MemberType && isDirty
                            ? errors.MemberType.message
                            : "Member Type"
                        }
                        defaultValue={"V"}
                        {...register("MemberType")}
                        disabled={isSubmitting}
                      >
                        <option value="A">Active Member</option>
                        <option value="V">Volunteer</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.AddressLine1 && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                        }`}
                        placeholder={
                          errors.AddressLine1 && isDirty
                            ? errors.AddressLine1.message
                            : "Permanent Address Line-1"
                        }
                        {...register("AddressLine1", {
                          required: "Please enter Address",
                        })}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.AddressLine2 && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                        }`}
                        placeholder={
                          errors.AddressLine2 && isDirty
                            ? errors.AddressLine2.message
                            : "Permanent Address Line-2"
                        }
                        {...register("AddressLine2")}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.CityName && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                        }`}
                        placeholder={
                          errors.CityName && isDirty
                            ? errors.CityName.message
                            : "City"
                        }
                        {...register("CityName", {
                          required: "Please enter your city",
                        })}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <select
                        className={`custom-select ${
                          errors.CountryId && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                        }`}
                        placeholder={
                          errors.CountryId && isDirty
                            ? errors.CountryId.message
                            : "Country"
                        }
                        defaultValue={0}
                        {...register("CountryId")}
                        disabled={isSubmitting}
                      >
                        <option key={0} value={0}>
                          Country
                        </option>
                        {countries.length > 0 &&
                          countries.map((item) => (
                            <option key={item.CountryId} value={item.CountryId}>
                              {item.Name.toUpperCase()}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <select
                        className={`custom-select ${
                          errors.StateId && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                        }`}
                        placeholder={
                          errors.StateId && isDirty
                            ? errors.StateId.message
                            : "Country"
                        }
                        defaultValue={0}
                        {...register("StateId")}
                        disabled={isSubmitting}
                      >
                        <option key={0} value={0}>
                          State
                        </option>
                        {states.length > 0 &&
                          states.map((item) => (
                            <option key={item.StateId} value={item.StateId}>
                              {item.Name.toUpperCase()}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <select
                        className={`custom-select ${
                          errors.DistrictId && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                        }`}
                        placeholder={
                          errors.DistrictId && isDirty
                            ? errors.DistrictId.message
                            : "Country"
                        }
                        defaultValue={0}
                        {...register("DistrictId")}
                        disabled={isSubmitting}
                      >
                        <option key={0} value={0}>
                          District
                        </option>
                        {districts.length > 0 &&
                          districts.map((item) => (
                            <option key={item.CityId} value={item.CityId}>
                              {item.Name.toUpperCase()}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.Pincode && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                        }`}
                        placeholder={
                          errors.Pincode && isDirty
                            ? errors.Pincode.message
                            : "Pincode"
                        }
                        {...register("Pincode", {
                          validate: pincodeValidate,
                        })}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.PhoneNumber && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                        }`}
                        placeholder={
                          errors.PhoneNumber && isDirty
                            ? errors.PhoneNumber.message
                            : "Phone No."
                        }
                        {...register("PhoneNumber", {
                          required: "Please enter your phone number",
                          maxLength: {
                            value: 10,
                            message: "Please enter a valid mobile number",
                          },
                          minLength: {
                            value: 10,
                            message: "Please enter a valid mobile number",
                          },
                          validate: phoneValidate,
                        })}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.RecommendedBy && isDirty
                            ? "red-placeholder border border-danger"
                            : ""
                        }`}
                        placeholder={
                          errors.RecommendedBy && isDirty
                            ? errors.RecommendedBy.message
                            : "Recommended By"
                        }
                        {...register("RecommendedBy", {
                          required: "Please enter Recommended By",
                        })}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          accept="image/*"
                          id="customFile"
                          {...register("UploadedFile")}
                          disabled={isSubmitting}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFile"
                        >
                          Upload Your Image
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 row pb-4">
                    {isDirty &&
                      errors &&
                      Object.values(errors).map((error, index) => (
                        <div className="col-sm-6" key={index}>
                          <p className="text-danger text-left p-0 m-0">{`* ${error.message}`}</p>
                        </div>
                      ))}
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      {isSubmitting ? (
                        <button
                          className="btn green-btn px-5 cursor-na"
                          disabled
                        >
                          <Spinner size="sm" />
                        </button>
                      ) : (
                        <input
                          type="submit"
                          className="btn green-btn"
                          value="Submit"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        showModal={showModal}
        closeModal={() => {
          setShowModal(false);
        }}
        heading={message.heading}
        summary={message.description}
      />
    </section>
  );
};

export default Register;
