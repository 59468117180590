import React from "react";
import { Link } from "react-router-dom";

const Banner = ({ imgUrl, heading, breadCrumb, alt }) => {
  return (
    <section className="inner-banner">
      <div className="inner-banner-img">
        <img src={imgUrl} alt="" />
      </div>
      <div className="banner-overlay">
        <div className="container">
          <h2>{heading ?? alt}</h2>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              {breadCrumb ? (
                breadCrumb.map((b) => (
                  <li className="breadcrumb-item">
                    <Link to={b.url}>{b.title}</Link>
                  </li>
                ))
              ) : (
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
              )}
              <li className="breadcrumb-item active" aria-current="page">
                {heading ?? alt}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default Banner;
